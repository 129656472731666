// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function ConfPromise() {
  return (
    <>
      <Helmet>
        <title>Confidentiality Promise</title>
      </Helmet>
      <Typography variant={'h3'}>Confidentiality Promise</Typography>
      <Typography variant={'subtitle2'} paragraph>Date: 1st January 2024</Typography>
      <Typography paragraph>Clippitt gives creators on our platform ("Creators") the tools to establish membership businesses by connecting directly with their most dedicated supporters and converting them into paying members ("Our platform users/subscribers/supporters"). To facilitate this direct connection and ensure that membership rewards and obligations are met, Clippitt provides personal data of users ("Clippitt Data") to Creators at certain times. The Creators then utilise the Clippitt Data in order to offer services as part of their business on Clippitt called "Subscriptions" or "Pay per View". To safeguard users' privacy rights when processing Clippitt Data, all Creators are required to adhere to this Data Processing Agreement known as our "Confidentiality Promise" provided by Clippitt.</Typography>
      <Typography paragraph>This Privacy Commitment applies to the data collected by Clippitt and shared with Creators for operating a membership business. It is linked as an extension to Clippitt's Terms of Service and Confidentiality Policy, detailing specific obligations for Creators in handling Clippitt data during and after their association with Clippitt.</Typography>

      <Typography variant={'h4'}>Definitions</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='"Data Protection Legislation" i.e. Privacy laws and regulations means all laws related to the handling of personal data, as well as any guidance or codes of practice from supervisory authorities in relevant jurisdictions, are referred to as Data Protection Legislation. This encompasses various laws such as European Directives 95/46/EC and 2002/58/EC (as amended by Directive 2009/136/EU), along with any legislation implementing or derived from them, including the General Data Protection Regulation (Regulation 2016/279). "Privacy laws and regulations", refers to all the relevant laws that govern the privacy and handling of personal data in any applicable jurisdiction. This encompasses guidelines, codes of practice issued by supervisory authorities, European Directives 95/46/EC and 2002/58/EC (as amended by Directive 2009/136/EC), as well as any legislation or regulation implementing or made pursuant to them. This also includes the General Data Protection Regulation (Regulation 2016/279) and any subsequent amendments, replacements, re-enactments or consolidations thereof.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='"Good Industry Practice" refers to the application of equivalent skill, expertise, and judgment, as well as the use of facilities and resources of comparable quality. This is expected from a professional who is skilled and experienced in delivering the relevant services, and strives to fulfil contractual obligations in good faith while aiming to minimise liability under any applicable duty of care. Additionally, they take proper and reasonable care, diligently perform their duties, and adhere to Data Protection Legislation.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The definitions of "data controller", "data processor," "sub processor," "data subject," "personal data," "processing," and "appropriate technical and organisational measures" should be understood in alignment with Directive 95/46/EC, or other relevant Data Protection Legislation, in the applicable jurisdiction.' />
        </ListItem>
      </List>

      <Typography variant={'h4'}>Scope</Typography>
      <Typography paragraph>The parties acknowledge that Clippitt acts as a data controller and Creator functions as a data processor for processing Clippitt Data related to the provision of Membership Services. The scope of the data processing, including the types of personal data involved and the categories of individuals affected, will be confined to what is essential for delivering the Membership Services. Upon leaving the Clippitt platform, Creators will undertake the processing covered by this Privacy Promise. This includes specifying the subject matter, duration, nature, purpose, type of personal data processed, and categories of individuals involved in accordance with this Confidentiality Promise and as follows: -</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The data processing pertains to information related to Clippitt Data.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The processing will continue for the duration that a Creator holds Clippitt Data.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='This Confidentiality Promise limits the nature and purpose of the processing to Creators fulfilment of Membership Services for users.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Personal data included in this agreement consists of contact details such as usernames, email addresses, personal addresses, locations and contact numbers including payment amounts and Clippitt search history.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The individuals affected by this data are users of the website and its apps.' />
        </ListItem>
      </List>
      
      <Typography variant={'h4'}>Data Protection. Creators shall adhere to the following requirements:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Processing as instructed. Creators are obligated to process Clippitt Data solely in line with the Clippitt Terms of Service, Confidentiality Policy, and this Confidentiality Promise. The processing should only serve the purpose of carrying out these instructions and must not be for the Creators own use or any other purpose unless mandated by law. In case legal requirements demand personal data processing for any other purpose, Creators will notify Clippitt beforehand if allowed by law.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Extent of Extent of Processing.The creator will only handle personal data to the extent required for providing Membership Services.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Appropriate Technical and Organisational Measures.Creator will establish and uphold suitable technical and organisational measures to safeguard personal data from unauthorised or unlawful processing, as well as accidental loss, destruction, damage, theft, alteration or disclosure. These measures will be tailored to the potential harm resulting from any unauthorised or unlawful processing or accidental loss of personal data. They will also align with Data Protection Regulations and Best Industry Practice while considering the nature of the data being protected.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Transfer to Third Parties.The creator is prohibited from granting any third-party access to or transferring personal data without the prior consent of Clippitt. In addition, the creator must guarantee the reliability and competence of any third parties, including their employees or agents who may have access to the personal data in providing Membership Services. Any contracts with such third parties must include provisions that protect Clippitt and are equivalent to those outlined in this Confidentiality Promise and the Terms of Service, as required by relevant Data Protection Legislation.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Reliability and Competence of Creator Personnel. The Creator is committed to ensuring the trustworthiness and proficiency of its personnel who have access to Clippitt Data. It will inform and educate all relevant personnel about the sensitive nature of personal data and ensure their compliance with the responsibilities outlined in this Confidentiality Promise.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Acknowledgement of Data Protection Legislation and Assistance.The creator is committed to supporting Clippitt in adhering to relevant Data Protection Legislation by taking necessary measures. This includes promptly notifying Clippitt in writing of any data subject requests or complaints related to Clippitts obligations under the applicable legislation.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Destruction or Return of Property Upon Membership Services Completion. Upon completion of the Membership Services, the Creator will promptly destroy or return the personal data as per Clippitts request.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Loss or Security Breach.If the Creator discovers any unintentional, unauthorised, or illegal destruction, loss, modification, disclosure of, or access to Clippitt Data during the provision of Membership Services, it is obligated to take the following actions:' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Provide notice to Clippitt. Creators shall promptly notify Clippitt and provide detailed information about any Loss or Security Breach, including the type of data involved, affected individuals (if known), and the steps taken to mitigate and remediate the breach, as well as periodic updates to this information and any other information Clippitt may reasonably request relating to the Loss or Security Breach; And..' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Investigate the Matter promptly.Creators shall investigate the issue quickly. And they take action to identify, prevent, and lessen the impact of the problem at their own cost, and implement appropriate recovery measures to resolve or remedy Loss or Security breaches.' />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Compliance with Data Protection Legislation.Compliance with Data Protection Legislation. The Creator must always comply with and assist Clippitt in complying with its obligations under Data Protection Legislation. The Creator will provide the necessary information to demonstrate compliance upon request by Clippitt, and notify them immediately if any instruction for processing personal data violates a country’s data privacy legislation.' />
        </ListItem>
      </List>
  </>
  );
}
