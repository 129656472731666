import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/system';
import { Box, Typography, Container } from '@mui/material';

import { getLatestVideosContent } from 'src/firebase/documentQueries';
import { DocumentData, QueryDocumentSnapshot, getDocs } from 'firebase/firestore';
import { useSnackbar } from 'src/components/snackbar';
import VideoCard from 'src/components/video-card/VideoCard';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function LatestPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [videoData, setVideoData] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);

  useEffect(() => {
    const queryVideos = async () => {
      const latest = getLatestVideosContent(80);
      try {
        const snapshot = await getDocs(latest);
        setVideoData(snapshot.docs);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Unable to fetch content!', { variant: 'error' });
      }
    }
    queryVideos();
  }, [enqueueSnackbar]);

  return (
    <StyledContainer>
      <Helmet>
        <title>Latest Videos</title>
      </Helmet>

      <Typography variant={'h2'}>Latest Videos</Typography>
      
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
      >
        {videoData.map((video) => (
          <VideoCard video={video.data()} videoId={video.id} key={video.id} />
        ))}
      </Box>
    </StyledContainer>
  );
}
