
// form
import { useForm } from 'react-hook-form';
import FormProvider, { RHFUploadVideo } from '../hook-form';
// @mui
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { MouseEvent, useCallback, useState } from 'react';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from '@aws-sdk/client-s3';
import { MediaType, UploadContent } from 'src/firebase/interfaces';
import { fData } from 'src/utils/formatNumber';
import { useSnackbar } from 'src/components/snackbar';
import { confirmUpload, uploadMedia } from 'src/firebase/functions';
import Iconify from '../iconify';
import { Launch } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

type UploadContentDialogProps = {
  open: boolean;
  type: MediaType;
  handleClose: (shouldRefresh: boolean) => void;
  contentId?: string;
}

export default function UploadContentDialog({ open, handleClose, type, contentId }: UploadContentDialogProps) {
  const methods = useForm<UploadContent>();
  const [progress, setProgress] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const progressBuffer = 5;

  const {
    handleSubmit,
    setValue,
    resetField,
    watch,
    formState: { isSubmitting }
  } = methods;

  const upload = watch('video');
  const title = type === MediaType.Video ? 'Content' : 'Trailer';

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('video', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    resetField('video');
  }

  const onClose = () => {
    handleClose(false);
  }

  const onSubmit = async () => {
    if (upload) {
      try {
        setProgress(5);
        const credentials = await uploadMedia(upload.name);

        const client = new S3Client({
          region: credentials.region,
          credentials: {
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
          },
        });

        const params = {
          Bucket: credentials.bucket,
          Key: credentials.objectKey,
          Body: upload
        };

        const uploadTask = new Upload({
          client,
          params,
        });
      
        uploadTask.on('httpUploadProgress', (progress) => {
          const actualProgress = (progress.loaded || 0) / (progress.total || 0) * 100;
          setProgress(actualProgress !== 100 ? actualProgress + progressBuffer : actualProgress);
        });
      
        await uploadTask.done();
        await confirmUpload(type, credentials.videoId, credentials.apiRequestUrl, upload.name, contentId);
        resetField('video');
        handleClose(true);
        enqueueSnackbar(`Your ${title} is uploaded and will be available in a few minutes.`, { variant: 'success' });
      } catch (e) {
        console.log(`Error uploading ${title}`, e);
        enqueueSnackbar(`Unable to upload ${title}, please try again`, { variant: 'error' });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      {!isSubmitting && (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: 'primary.main' }}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      )}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Upload {title}</DialogTitle>

        <DialogContent sx={{overflowY: 'visible'}}>
          <Stack>
          <RHFUploadVideo
            disabled={isSubmitting}
            name="video"
            maxSize={5000000000}
            onDrop={handleDrop}
            onDelete={!isSubmitting ? handleDelete : undefined}
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.secondary',
                }}
              >
                Almost all possible video formats are supported
                <br /> max size of {fData(5000000000)}
              </Typography>
            }
          />
          </Stack>
          {isSubmitting && (
            <Box sx={{mt: 3, mb: 1}}>
              <Typography variant="caption" sx={{display: 'block', textAlign: 'center', textTransform: 'uppercase', mb: 1}}>Upload in progress</Typography>
              <LinearProgress variant='determinate' value={progress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction={'column'} sx={{flex: 1, alignItems: 'center'}}>
            <LoadingButton type="submit" variant="contained" fullWidth loading={isSubmitting} disabled={!upload}>
              Begin Upload
            </LoadingButton>
            <Link component={RouterLink} to="/" sx={{textAlign: 'center', mt: 2, width: '100%'}} target="_blank">Continue Browsing <Launch sx={{fontSize: 14}} /></Link>
          </Stack>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
