import { useDropzone } from 'react-dropzone';
// @mui
import { Box, IconButton, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
//
import Iconify from '../iconify';
//
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import SingleFilePreview from './preview/SingleFilePreview';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  width: '100%',
  aspectRatio: '16/9',
  margin: 'auto',
  display: 'flex',
  cursor: 'pointer',
  overflow: 'hidden',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  borderRadius: 10,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
}));

const StyledPlaceholder = styled('div')(({ theme }) => ({
  zIndex: 7,
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: `100%`,
  height: `100%`,
  borderRadius: 10,
  color: theme.palette.text.disabled,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function UploadVideo({
  error,
  file,
  disabled,
  helperText,
  sx,
  onDelete,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    ...other,
  });

  const hasFile = !!file;

  const isError = isDragReject || !!error;

  return (
    <Box sx={{position: 'relative'}}>
      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: -10,
            right: -10,
            zIndex: 10,
            position: 'absolute',
            color: (theme) => theme.palette.common.white,
            bgcolor: (theme) => theme.palette.grey[900],
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            borderColor: 'error.light',
            ...(hasFile && {
              bgcolor: 'error.lighter',
            }),
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasFile && {
            '&:hover': {
              '& .placeholder': {
                opacity: 1,
              },
            },
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />

        {hasFile && <SingleFilePreview file={file} isVideo={true} />}

        <StyledPlaceholder
          className="placeholder"
          sx={{
            '&:hover': {
              opacity: 0.72,
            },
            ...(hasFile && {
              zIndex: 9,
              opacity: 0,
              color: 'common.white',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
            }),
            ...(isError && {
              color: 'error.main',
              bgcolor: 'error.lighter',
            }),
          }}
        >
          <Iconify icon="bxs:video-plus" width={24} sx={{ mb: 1 }} />

          <Typography variant="caption">{hasFile ? 'Drop or Select New Video' : 'Drop or Select Video'}</Typography>
        </StyledPlaceholder>
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />
    </Box>
  );
}
