import { Navigate, useRoutes } from 'react-router-dom';
// auth
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
// import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  // GeneralAppPage,
  GeneralAnalyticsPage,
  // Dashboard: User
  // UserListPage,
  // UserEditPage,
  // UserCardsPage,
  // UserCreatePage,
  // UserProfilePage,
  // UserEditPage,
  UserAccountPage,
  // Dashboard: App
  // ChatPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  HomePage,
  VideoDetailsPage,
  ChannelDetailsPage} from './elements';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import { UserRole } from 'src/firebase/interfaces';
import AuthGuard from 'src/auth/AuthGuard';
import ContentEdit from 'src/sections/user/content/ContentEdit';
import LatestPage from 'src/pages/LatestPage';
import TrendingPage from 'src/pages/TrendingPage';
import AboutPage from 'src/pages/AboutPage';
import FavouritesPage from 'src/pages/FavouritesPage';
import HistoryPage from 'src/pages/HistoryPage';
import SuggestedPage from 'src/pages/SuggestedPage';
import SupportPage from 'src/pages/SupportPage';
import LegalPage from 'src/pages/LegalPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: '/',
      element: (
        <DashboardLayout />
      ),
      children: [
        { element: <Navigate to="home" replace />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'latest', element: <LatestPage /> },
        { path: 'trending', element: <TrendingPage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'support', element: <SupportPage /> },
        {
          path: 'legal',
          children: [
            { element: <Navigate to="terms" replace />, index: true },
            {
              path: ':tab?',
              element: <LegalPage />,
            },
          ],
        },
        { path: 'favourites', element: <FavouritesPage /> },
        { path: 'history', element: <HistoryPage /> },
        { path: 'suggested', element: <SuggestedPage /> },
        { path: 'watch', 
          children: [
            { path: ':videoId', element: <VideoDetailsPage /> }
          ]
        },
        { path: 'channel',
          children: [
            { path: ':channelId', element: <ChannelDetailsPage />}
          ]
        },
        // { path: 'dashboard', element: <GeneralAppPage /> },
        { path: 'analytics', element: (
          <RoleBasedGuard roles={[UserRole.Admin]} hasContent={true}>
            <GeneralAnalyticsPage />
          </RoleBasedGuard>
        )
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="account/general" replace />, index: true },
            {
              path: 'account/:tab?/:contentId?',
              element: (
                <AuthGuard>
                  <UserAccountPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: 'media/:contentId',
          element: (
            <AuthGuard>
              <ContentEdit />
            </AuthGuard>
          )
        },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <ChatPage />, index: true },
        //     { path: 'new', element: <ChatPage /> },
        //     { path: ':conversationKey', element: <ChatPage /> },
        //   ],
        // },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
