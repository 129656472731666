import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getStorage } from 'firebase/storage';
import { FIREBASE_API } from '../config-global';

const app = initializeApp(FIREBASE_API);

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west2');

if (process.env.REACT_APP_FIREBASE_EMULATOR_ENABLED === 'true' && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

if (process.env.REACT_APP_FIREBASE_APPCHECK_ENABLED === 'true') {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APPCHECK_SITE_KEY || ''),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });
}

export enum FirestoreCollection {
  Content = 'content',
  Profile = 'profiles',
  User = 'users',
  Payments = 'payments',
  Subscriptions = 'subscriptions',
  Likes = 'likes',
  Favourites = 'favourites',
  Follows = 'follows',
  Views = 'views',
  Subscribers = 'subscribers',
}

export enum FirestoreOperator {
  Created = 'createdAt',
  Updated = 'updatedAt',
  Status = 'status',
  Creator = 'creator',
  Views = 'views',
  Purchases = 'purchases',
  Entity = 'entity',
}