import { collection, doc, orderBy, query, where, limit, DocumentData, Query, getDoc, documentId } from 'firebase/firestore';
import { firestore, FirestoreCollection, FirestoreOperator } from '.';
import { CheckoutType, ContentType, PaymentStatus } from './interfaces';
import { ActionEntity } from './functions';

export const getContentRef = () => {
  return collection(firestore, FirestoreCollection.Content);
};

export const getUsersRef = () => {
  return collection(firestore, FirestoreCollection.User);
};

export const getUserRef = (userId: string) => {
  const usersRef = getUsersRef();
  return doc(usersRef, userId);
};

export const getProfilesRef = () => {
  return collection(firestore, FirestoreCollection.Profile);
};

export const getProfileRef = (channelId: string) => {
  const profilesRef = getProfilesRef();
  return doc(profilesRef, channelId);
};

export const getVideosContent = (total: number = 20) => {
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Updated, 'desc'), limit(total));
}

export const getLatestVideosContent = (total: number = 20) => {
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Updated, 'desc'), limit(total));
}

export const getTrendingVideosContent = (total: number = 20) => {
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Views, 'desc'), limit(total));
}

export const getViewedVideoIds = (userId: string) => {
  const userRef = getUserRef(userId);
  const viewsRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Views);
  return query(viewsRef, orderBy(FirestoreOperator.Created, 'desc'), where(FirestoreOperator.Entity, '==', ActionEntity.Content), limit(100));
}

export const getFavouriteVideoIds = (userId: string) => {
  const userRef = getUserRef(userId);
  const viewsRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Favourites);
  return query(viewsRef, orderBy(FirestoreOperator.Created, 'desc'), where(FirestoreOperator.Entity, '==', ActionEntity.Content), limit(100));
}

export const getSubscriberIds = (userId: string) => {
  const userRef = getProfileRef(userId);
  const subscribersRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Subscribers);
  return query(subscribersRef, orderBy(FirestoreOperator.Created, 'desc'), limit(100));
}

export const getVideoContentByIds = async (ids: string[], total: number = 8) => {
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, where(documentId(), 'in', ids), limit(total));
}

export const getProfilesByIds = async (ids: string[], total: number = 30) => {
  const contentRef = collection(firestore, FirestoreCollection.Profile);
  return query(contentRef, where(documentId(), 'in', ids), limit(total));
}

export const getVideosByUserIdCreatedData = (creatorId: string, total: number = 10) => {
  const creatorRef = getProfileRef(creatorId);
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, where('status', 'in', ['active', 'draft', 'pending', 'error']), orderBy(FirestoreOperator.Creator), where('creator', '==', creatorRef), orderBy(FirestoreOperator.Created, 'desc'), limit(total));
}

export const getPayments = (userId: string, total: number = 10) => {
  const userRef = getUserRef(userId);
  const paymentsRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Payments);
  return query(paymentsRef, orderBy('created', 'desc'), where(FirestoreOperator.Status, '==', PaymentStatus.Succeeded), where('metadata.type', '==', CheckoutType.Purchase), limit(total));
}

export const getPayment = (userId: string, id: string) => {
  const userRef = getUserRef(userId);
  const paymentsRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Payments);
  return query(paymentsRef, where(FirestoreOperator.Status, '==', PaymentStatus.Succeeded), where('metadata.id', '==', id));
}

export const getSubscriptions = (userId: string, total: number = 8) => {
  const userRef = getUserRef(userId);
  const subscriptionsRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Subscriptions);
  return query(subscriptionsRef, orderBy('created', 'desc'), limit(total));
}

export const getSubscription = (userId: string, id: string) => {
  const userRef = getUserRef(userId);
  const subscriptionsRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Subscriptions);
  return query(subscriptionsRef, where('metadata.id', '==', id));
}

export const getMostRecentVideosContentByUserId = (creatorId: string) => {
  const creatorRef = getProfileRef(creatorId);
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Creator), where('creator', '==', creatorRef), orderBy(FirestoreOperator.Created, 'desc'), limit(10));
}

export const getMostViewedVideosContentByUserId = (creatorId: string) => {
  const creatorRef = getProfileRef(creatorId);
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Creator), where('creator', '==', creatorRef), orderBy(FirestoreOperator.Views, 'desc'), limit(10));
}

export const getMostPurchasedVideosContentByUserId = (creatorId: string) => {
  const creatorRef = getProfileRef(creatorId);
  const contentRef = collection(firestore, FirestoreCollection.Content);
  return query(contentRef, orderBy(FirestoreOperator.Purchases, 'desc'), orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Creator), where('creator', '==', creatorRef), where('type', '==', ContentType.Purchase), where(FirestoreOperator.Purchases, '!=', 0), limit(10));
}

export const getVideoContent = (videoId: string) => {
  const videosRef = getContentRef();
  const videoRef = doc(videosRef, videoId);
  return videoRef;
}

export const getRelatedVideosContent = (total: number, videoId: string) => {
  const contentRef = collection(firestore, FirestoreCollection.Content);
  const queryResults = total ? 
    query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Created, 'desc'), limit(total + 1)) 
    : 
    query(contentRef, orderBy(FirestoreOperator.Status), where('status', '==', 'active'), orderBy(FirestoreOperator.Created, 'desc'));
  return queryResults;
}

export const getUserContentLike = async (userId: string, videoId: string): Promise<boolean> => {
  const userRef = getUserRef(userId);
  const likesRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Likes);
  const like = doc(likesRef, videoId);
  const snapshot = await getDoc(like);
  return snapshot.exists();
}

export const getUserContentFavourite = async (userId: string, videoId: string): Promise<boolean> => {
  const userRef = getUserRef(userId);
  const favouritesRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Favourites);
  const favourite = doc(favouritesRef, videoId);
  const snapshot = await getDoc(favourite);
  return snapshot.exists();
}

export const getUserCreatorFollow = async (userId: string, creatorId: string): Promise<boolean> => {
  const userRef = getUserRef(userId);
  const favouritesRef = collection(firestore, userRef.path + '/' + FirestoreCollection.Follows);
  const favourite = doc(favouritesRef, creatorId);
  const snapshot = await getDoc(favourite);
  return snapshot.exists();
}

interface VideosType {
  mostRecent: Query<DocumentData>;
  mostViewed: Query<DocumentData>;
  mostPurchases: Query<DocumentData>;
}

export const getChannelDetailsPageVideos = (creatorId: string) => {
  const mostRecent = getMostRecentVideosContentByUserId(creatorId);
  const mostViewed = getMostViewedVideosContentByUserId(creatorId);
  const mostPurchases = getMostPurchasedVideosContentByUserId(creatorId);
  const data: VideosType = {
    mostRecent,
    mostViewed,
    mostPurchases,
  }
  return data;
}