// @mui
import { Helmet } from 'react-helmet-async';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

export default function LegalTerms() {
  return (
    <>
      <Helmet>
        <title>Terms of Use</title>
      </Helmet>

      <Typography variant={'h3'}>Terms of Use</Typography>
      <Typography variant={'subtitle2'} paragraph>Effective 1st Jan, 2024</Typography>

      <Typography paragraph>Welcome to Clippitt! The new player in town.</Typography>

      <Typography paragraph>Clippitt is a membership platform connecting supporters (i.e. their fans and followers) with their favourite creators. Our mission is to prioritise creators, and our terms aim to reflect that.</Typography>

      <Typography paragraph>In summary, by using Clippitt and we are referring to Clippitt.com, you agree to the Terms of Use that apply to all users. This includes our Confidentiality Policy, Clippitt Community Guidelines, and User Guidelines. Please read them carefully and reach out if you have any questions about our data practices as outlined in our Clippitt Confidentiality Policy and Clippitt Cookies Policy.</Typography>
      <Typography paragraph>We collect, use, and share information in accordance with those policies.</Typography>

      <Typography variant={'h4'}>Age Restrictions Apply</Typography>

      <Typography paragraph>To summarise: Users of the platform unaccompanied by an adult, must be at least 13 years old to view content on Clippitt and are required to take responsibility for their own account and behaviour while using the site. Children under 13 are can only view Clippitt '"clips" or "content" if supervised by an adult i.e. 18 and above. And they cannot create or 'register' for an account. When you register, it's essential to provide accurate information and agree to update it promptly. To sign up or utilise Clippitt as a registered member, join our community and have access to the viewing of content, users must be at least 13 years old and must also have the legal capacity to consent to the processing of personal data in their country (in some locations, a parent or guardian may act on their behalf).  In addition, individuals need to be at least 18 years old if they wish to own a creator channel on Clippitt or make purchases within the platform. Subscribing to or viewing Pay Per View content from our Clippitt creators requires being over 18 as well. It is crucial for users to take ownership of any activity associated with their account by maintaining its security, taking necessary action if an account is compromised, and following our Clippitt Security Policy for further guidance.</Typography>


      <Typography variant={'h4'}>Your Clippitt account</Typography>

      <Typography paragraph>To summarise: It's crucial to act responsibly and adhere to our terms or policies.</Typography>
      <Typography paragraph>You are accountable for all the activities carried out using your account. If you breach our terms or policies, we reserve the right to close your account. Please refrain from engaging in any illegal, abusive, or technical exploits that could harm Clippitt, including the unauthorised use of Clippitt as a storage platform. As a creator earning income on Clippitt, your actions with those funds can pose risks to us and we will act accordingly and swiftly if we believe this is the case. We may also consider your activity outside of Clippitt due to its potential impact on the community. More detailed information is available in the Clippitt Community Guidelines and Clippitt Security Policy: While these guidelines cover most situations, if you devise new methods harmful to Clippitt or our community, we will take measures against it.</Typography>

      <Typography variant={'h4'}>Being a 'Clippitt Creator' - Earn 80% on all platform transaction fees.</Typography>

      <Typography paragraph>In summary, a creator is an individual who establishes a channel on Clippitt to interact with supporters/fans who buy memberships or Pay-per-view (we refer to this as PPV) content on Clippitt in order to back/support the creator's work. Below are many specific aspects related to payments, charges, taxes, and constraints that creators should thoroughly review.</Typography>

      <Typography variant={ 'h4'}>Creator's own channel</Typography>
  
      <Typography paragraph>Becoming a creator on Clippitt is as simple as launching your channel to kickstart your offerings or monthly membership. By joining Clippitt, you become an integral part of its vibrant community. And by utilising the provided creator tools to showcase your creations, you can actively engage with the Clippitt community, and when the product sync feature is released as part of our enhanced feature rollout roadmap, you will be able to offer merchandise to users through our specialised merchant services. Memberships cater to your most dedicated fans, allowing you to provide them with enticing benefits such as exclusive access, soon-to-launch personalised messaging features, unique creations, and immersive community experiences. In return for their loyal support and subscription payments through memberships and purchases on Clippitt's platform, you will receive valuable revenue while nurturing a strong relationship with your supporter/fan base.</Typography>
  
      <Typography variant={ 'h4'}>Payments to our creators</Typography>
      <Typography paragraph>As a content creator, you have the option to offer your work on Clippitt as either one-time offerings (PPV) or through subscription memberships. As we are currently UK-based, the prices shown will be in pounds sterling although Stripe will convert the price for you automatically into your own local currency. We handle payment issues such as fraud, chargebacks, and disputes in a timely manner and aim to resolve any queries within two to three working days. Different types of transactions may have varying timelines for accessing funds earned on Clippitt, with potential reserves being withheld. In certain cases, access to funds may be blocked or withheld due to violations of terms/policies or compliance reasons; if this occurs, we aim to promptly communicate the reason with you. We also reserve the right to block subscribers' payments if deemed fraudulent in order to protect creators from negative balances resulting from activities like refunds and chargebacks - any incurred fees may not be refunded when this happens.</Typography>

      <Typography variant={ 'h4'}>Fees</Typography>
      <Typography paragraph>As a content creator, there are charges linked to your purchasable content on Clippitt. The specific details of these charges are outlined below, but for additional support, please email our customer experience team on support@clippitt.com for a timely response. These fees may be subject to change with prior notice and generally include all payment processing fees (unless additional fees are due in certain countries) see 'Currency conversion fees' and 'Tax' further down in the document. The standard fee is detailed and fixed as per below and does not differ for all of Clippitt's platform, payment or payout fees.</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The "platform processing fee" constitutes a flat fee and is a percentage of successfully processed payments, totalling 20% of the total transaction.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The 20% fee mentioned above includes all the "payment processing fees" (apart from "currency conversion fees" - see below) which is calculated based on successfully processed payments. This fee accounts for the expenses associated with handling user payments, including recurring billing, addressing declined payments, and combating fraudulent transactions.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='The "payout fee" for transferring your earnings from Clippitt to your specified payout method is fixed at 80% of the total transaction fee received.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='In some countries, a "currency conversion fee" may be applied to successfully processed payments made in a currency different from your payout currency. Please note that Clippitt does not have any control over the setting of these fees.' />
        </ListItem>
      </List>
      <Typography paragraph>You can see the fees for any membership subscription on your 'My Account' dashboard, though please note that this dashboard does not list the payout fee described above.</Typography>
      <Typography paragraph>If a supporter pays in a currency that is different from the currency associated with their payment instrument, then the financial institution associated with that payment instrument may charge them a foreign transaction fee for that payment. We are not applying this fee and have no control over it, have no knowledge of whether the fee is applied, and do not receive any of the funds from that fee. Even if a supporter pays in the same currency as the currency associated with their payment instrument, when a supporter's payment is processed in a country different from the one associated with their payment instrument, then the financial institution associated with that payment instrument may charge them a foreign transaction fee for that payment. We are not applying this fee, have no knowledge of whether the fee is applied, and do not receive any of the funds from that fee, but our choices about payment processing may impact or otherwise give rise to this fee.</Typography>

      <Typography paragraph>You can view the purchased PPV fees on your 'My Account' dashboard. However, please be aware that this dashboard does not display the payout fee mentioned previously. If a supporter makes a payment in a currency different from the one associated with their payment method, the financial institution linked to that payment method might impose a foreign transaction fee. We do not impose this fee, have no influence over it, are unaware of its application, and do not receive any part of such fees. Even if a supporter pays in the same currency as their payment instrument's associated currency but the processing takes place in another country, they may still incur foreign transaction fees charged by their financial institution. While we do not impose this fee and are uninformed about its application or receipt of funds from it, our choices regarding payment processing could affect or lead to this charge.</Typography>
  
      <Typography variant={ 'h4'}>Tax</Typography>
      <Typography paragraph>In order to fulfil our tax responsibilities, we often gather tax identification details and, when necessary, report this information and earnings to tax authorities as mandated by law. It is your responsibility to report any income-related taxes on the money earned through Clippitt. We also hold accountability for various transactional taxes including but not limited to Value Added Tax ("VAT"), potentially along with other local service or sales taxes outside of the UK. Collectively referred as "VAT" or Sales Tax "ST".</Typography>
      <Typography paragraph>If Clippitt is required to charge and collect tax in your jurisdiction, such as VAT or sales tax, the applicable tax is included in the total presented charge. Rest assured that all taxes collected are remitted to the proper taxing authority. Your cooperation in keeping your location information complete and up to date is crucial for accurate tax collection. Please note that if you pay in a currency different from the one associated with your payment instrument, the financial institution linked to that payment method may apply a foreign transaction fee. While we do not apply this fee and have no control over it, nor do we receive any of its funds.</Typography>

      <Typography variant={'h4'}>Personal Income Tax</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Income Tax for Individuals. Earnings from Clippitt are liable to income tax and national insurance if you are not contracted through a limited company. Consequently, it is essential to submit personal tax returns to HMRC for the earnings generated from the platform." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="HMRC has announced plans to focus on similar websites to Clippitt, therefore, it's crucial to proactively inform HMRC about your situation rather than risking potential consequences later on." />
        </ListItem>
      </List>
      <Typography variant={'h4'}>What do I need to file and when?</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="The personal tax year runs from 6th April to 5th April the following year, with the deadline for filing and payment of tax being 31st January. For example: -" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="For the period 6th April 2022 to 5th April 2023, due by 31st January 2024." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="For the period from 6th April `2023` to `5th`April `2024`, due by ‌31‌st Janu‌ary 2025." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="You will need to file a personal tax return for each year that you made income from a content site. Deduct allowable expenses from your content income to calculate taxable profit." />
        </ListItem>
      </List>

      <Typography variant={'h4'}>How much tax will I need to pay?</Typography>
      <Typography paragraph>The tax you owe on your content profits is determined by your total Clippitt income, which includes other sources such as wages, dividends, interest, and rental. This combined income may be subject to taxes at various rates including 20%, 40%, or 45%. If you find yourself in the higher tax bracket, consider transitioning to trading as a Limited Company.</Typography>
      
      <Typography variant={'h4'}>What should I do if I need to file?</Typography>
      <Typography paragraph>You should inform HMRC right away that you must submit a personal tax return, beginning from the tax year when you created your Clippitt content account. Upon notification, they will provide you with a Unique Tax Reference number that is required for filing your returns. The earlier you communicate your need to file, the more understanding they will be regarding any missed deadlines and potential penalties.</Typography>
      <Typography variant={'h4'}>What happens if I don't file?</Typography>
      <Typography paragraph>Failure to file taxes and unpaid taxes may incur penalties and interest charges. A late-filed return automatically receives a penalty of £100, with an additional daily charge of £10 for every day it remains overdue after three months. If the delay exceeds six months, a further charge of £300 or 5% of the tax owing - whichever is higher - applies, and this same charge is applied again if it becomes 12 months overdue. One way to potentially reduce your tax liability when earning around £40k or more is by considering better remuneration planning through forming a limited company.</Typography>
      <Typography variant={'h4'}>Restrictions</Typography>
      <Typography paragraph>We prohibit the creation and offering of content that violates our terms or policies. Please refer to our Clippitt Community Guidelines and User Guidelines for more details. In essence, we do not permit illegal content or offerings. Content or offerings that are abusive towards others, misleading or deceptive content or offerings, unauthorised use of others' intellectual property, unless you have proper permission to use it, or your use is protected as fair use - creating adult-oriented content involving real people in sexual acts are strictly prohibited. When targeting fans under 18 years old, the registered members must be 18 before making any purchases on Clippitt. Additionally, individuals under 13 years old cannot use any of Clippitt's services, i.e. they can only spectate/view Clippitt content with an adult present that has firstly viewed and deemed the content appropriate for their child or minor. We reserve the right to restrict access to specific users.</Typography>
      <Typography paragraph>As a creator, safeguarding users' data offered to you in the form of private messaging or notifications etc. is your responsibility. You must adhere to our Data Processing Agreement, also known as the Clippitt Confidentiality Policy at all times. Accounts are strictly linked to individual creative output and cannot be sold or transferred for usage by another creator. We continuously experiment with product features aimed at improving Clippitt which may involve adding/removing/testing features with select creators without guaranteeing access/control over these functionalities at all times. This process might entail additional restrictions at times and users will be notified of these upcoming changes. We make every effort to ensure any changes will not affect a creator's earning potential or create a situation whereby their content is not visible or purchasable within their own channel.</Typography>

      <Typography variant={'h4'}>User experience on Clippitt.</Typography>

      <Typography paragraph>In summary: A registered user (or supporter) refers to someone who subscribes/makes a purchase on Clippitt from their favourite creators and benefits from engaging directly through tools provided by us while connecting within a community comprising of supporters, users, guests, members and creators.</Typography>

      <Typography paragraph>By becoming a creator, you may enjoy exclusive perks such as mentions on our web banners, free marketing, special content for users, and mentions on various other media outlets all designed to drive additional revenue to a creator's channel.</Typography>
      <Typography paragraph>As a member of our community, you can choose to make either one-time payments or opt for recurring subscriptions managed by Clippitt. The frequency and cost of each subscription or offering vary based on the type of support or fee a particular creator set and is bespoke to each creator. Clippitt have no control over a creator's fees and the platform is only to be used as a conduit between supporters and creators.</Typography>
      <Typography paragraph>All details regarding purchases or subscriptions are transparent before making any commitments; furthermore, your active subscriptions and billing history are readily accessible.</Typography>

      <Typography variant={'h4'}>Joining Clippitt as a member</Typography>

      <Typography paragraph>To join as a member, simply go to the log-in section to be found by clicking on the top right avatar button, and follow the registration procedure by entering your name, email address and date of birth. You can now view and use all of Clippitt's free of charge features, including all of its purchasable features.</Typography>

      <Typography paragraph>As a member, should your chosen membership provide access to a creator's content, you will receive limited permission to view this material privately for personal use only. While it is typical for accessed creations to remain available indefinitely after purchasing them in this manner, do note that they might become inaccessible at any time under certain circumstances. Beyond individual PPV transactions, Clippitt also offers one distinct type of automatic renewing membership: i.e. monthly subscriptions with per-month payment schedules; per-creation subscriptions are not bound to any sort of auto-renew mechanism. Please be aware that not all creators offer monthly subscriptions —and all current options will be presented when viewing their content.</Typography>

      <Typography variant={'h4'}>Joining Clippitt as a creator</Typography>
      <Typography paragraph>Once you have successfully created your member account, and verified you are 18+ by entering your date of birth at the registration process, you can now become a Clippitt Creator. You will as a member automatically receive your own creator channel in anticipation of you becoming a creator yourself.  Once you have accepted our terms of agreement, you can now create your own channel branding and access our monetised services.</Typography>

      <Typography variant={'h4'}>Monthly membership renewal and fees</Typography>

      <Typography paragraph>Note: Each paid monthly subscription service automatically renews monthly until cancelled via your membership 'My Account' settings. If your subscription includes a free trial, you'll be charged after the specified free trial period ends unless you cancel via your membership settings before that time. Your recurring charge date may vary due to time zone differences and changes in month lengths. The amount for a membership subscription may increase with notice, allowing creators to adjust prices based on various factors such as costs and benefits. Likewise, currency exchange rates might be updated when subscribers pay in different currencies than those chosen by the creator. Applicable taxes are not controlled by Clippitt and may change without notice.</Typography>

      <Typography paragraph>You have the option to end your free trial or recurring payments at any time. If you decide to cancel or downgrade your membership, it will affect your next scheduled payment.</Typography>

      <Typography paragraph>Cancelling your membership may result in loss of access to subscription benefits, such as subscriber-only content and other perks. You may also lose access to purchased offerings if there are issues with your payment method, account termination, creator actions on Clippitt, or changes made by the creator. Additionally, we are not obligated to grant you access or interaction with any specific creator.</Typography>
      <Typography paragraph>Membership options and PPV offerings differ, and we have limited influence over the quality and specific perks. We strive to filter out deceptive creator channels but cannot assure the authenticity of creators or their claims. Your assistance in flagging suspicious creator pages is valuable for maintaining a secure Clippitt community. Please report anything that you believe to be suspicious.</Typography>
  
      <Typography variant={'h4'}>Foreign Membership Transactions</Typography>
      <Typography paragraph>Even if you use the same currency for payment as the one linked to your payment method, making a transaction in a different country may lead to foreign transaction fees imposed by the financial institution associated with that payment method. While we are not responsible for this fee or its application, our decisions regarding payment processing can influence or result in such charges being incurred.</Typography>

      <Typography paragraph>We are always innovating and refining product features to enhance Clippitt, which may involve adding, removing, or testing features with a select group of users. Access to these features is not assured and may not be within your control. Instead, we reserve the right to activate or deactivate these early-access, beta, or test features at our discretion. Furthermore, these features could be accompanied by additional restrictions and limitations.</Typography>

      <Typography variant={'h4'}>Refunds</Typography>

      <Typography paragraph>Our standard practice is not to offer refunds, even in cases where access to our products and/or membership benefits is lost as outlined above. However, we may consider certain exceptions where refunds are given at our discretion.</Typography>

      <Typography variant={'h4'}>Clippitt 's role</Typography>

      <Typography paragraph>We review content on Clippitt to enforce our terms and policies, including the Clippitt Community Guidelines and User Guidelines. We also investigate reports of potential violations, which may involve reviewing activity outside of the platform due to associated risks. In most cases, we collaborate with creators and platform users to address any policy breaches before considering account termination as a last resort in order to protect all platform users and the community at large.</Typography>

      <Typography variant={'h4'}>Overview of UK sanctions</Typography>
      <Typography paragraph>Sanctions are restrictive measures that can be put in place to fulfil a range of purposes. In the UK, these include complying with UN and other international obligations, supporting foreign policy and national security objectives, as well as maintaining international peace and security, and preventing terrorism.</Typography>
      <Typography paragraph>The UK implements a range of sanctions regimes through regulations made under the Sanctions and Anti-Money Laundering Act 2018 (the Sanctions Act). The Sanctions Act provides the main legal basis for the UK to impose, update and lift sanctions.</Typography>
      <Typography paragraph>UK sanctions regulations made under the Sanctions Act apply in the whole of the UK, including in Northern Ireland. The prohibitions and requirements in these Regulations apply to conduct by UK persons. This includes anyone in the UK (including its territorial waters), UK nationals outside of the UK, and bodies incorporated or constituted under the law of any part of the UK. It is government policy for UK sanctions measures to be given effect in the British Overseas Territories and Crown Dependencies to make sanctions as effective as possible. As required under section 30 of the Sanctions Act, the Foreign, Commonwealth & Development Office published the Sanctions Regulations Report on Annual Reviews for 2021.</Typography>
      <Typography paragraph>Some sanctions measures apply through other legislation, such as the Immigration Act 1971 and the Export Control Order 2008.</Typography>

      <Typography paragraph>Find out: more information on: -</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="UK sanctions policy from 11pm on 31 December 2020" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="where UK sanctions regimes apply" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="how to request a review of a sanctions decision" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="how to request the review of the specification or designation of a ship" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="who is subject to UK sanctions under the Sanctions Act" />
        </ListItem>
      </List>

      <Typography variant={'h4'}>Account deletion</Typography>

      <Typography paragraph>In summary, you have the option to delete your account at any time and your content will be removed automatically. We reserve the right to terminate or disable your account at our discretion. You can permanently erase your account anytime by contacting support@clippitt.com. We hold the authority to terminate or suspend your account as we see fit. This also includes cancelling any membership subscriptions and deleting any content, posts, services, or privileges at our sole discretion. You are not permitted to file a claim against us for terminating someone else's account and agree not to do so. If you attempt such action, you will be held accountable for all resulting damages including legal fees and expenses. These terms apply even if you no longer have an active account.</Typography>

      <Typography variant={'h4'}>Your creations</Typography>

      <Typography paragraph>To summarise: Creators retain full ownership of their creations and users maintain full ownership of the content they post on Clippitt. However, we require licenses for this material to effectively operate our services. By making creations available or posting on Clippitt, you grant us a royalty-free, perpetual, irrevocable, non-exclusive, sublicensable worldwide license in all formats and channels to use your creation or posted content. This includes rights to copy, reproduce, store, translate, transmit, distribute, perform. prepare derivative works, publicly display it in connection with any personal data it may contain within the scope of this license as well as utilise any name username voice or audio provided alongside consent.</Typography>

      <Typography paragraph>Running our services like hosting creations, offering memberships, and enabling community features is a big responsibility. Rest assured that you retain full ownership over your creations on Clippitt; we won't exploit or profit from them in any way other than facilitating memberships and offerings you've set up. By using Clippitt, you agree not to violate others' intellectual property rights, and we reserve the right to remove any content that goes against our terms and policies. We take copyright infringement seriously and have measures in place to address it accordingly. Information about this process can be found on our Clippitt copyright policy.</Typography>

      <Typography paragraph>Creators' creations are only for use by users who have purchased the offering or subscription through which those creations were made available. Unauthorised sharing of these creations is not permitted.</Typography>

      <Typography variant={'h4'}>Third-party apps and services</Typography>

      <Typography paragraph>You can choose to allow third-party apps and services access to your Clippitt account, and vice versa. You also have the option to revoke this access. When you link your Clippitt account with other websites, apps, or services, they may request authorisation to access information from your Clippitt account or perform actions on its behalf. If you proceed with this process, we will follow your instructions accordingly. For more details, please refer to our Clippitt Confidentiality Policy.</Typography>

      <Typography variant={'h4'}>Clippitt's creations</Typography>

      <Typography paragraph>In summary, any bug reports or suggested improvements you provide to Clippitt will be fully owned by Clippitt. Our creations are protected by copyright, trademark, patent, and trade secret laws. We grant creators a license to use our logo and other copyrights or trademarks to promote their Clippitt pages. You may not otherwise use, reproduce, distribute, perform publicly display or prepare derivative works of our creations unless we give you permission in writing. We welcome your feedback on the performance of the Clippitt product and appreciate your help in identifying any problems or defects. Any suggestions, comments, ideas, bug reports or improvements that you provide to us will be fully owned by us for improving Clippitt.</Typography>

      <Typography variant={'h4'}>Indemnity</Typography>

      <Typography paragraph>In brief, if there is a legal claim against us due to your actions on Clippitt, you are responsible for contributing towards the costs. You agree to compensate us for any losses and expenses, including legal costs, that result from these terms or your use of Clippitt. We have the right to take full control of defending a claim under this provision. Should we choose to do so, you are required to assist in our defence efforts. Your responsibility to cover indemnification also extends to our subsidiaries, affiliates, officers, directors, employees, agents and third-party service providers.</Typography>

      <Typography variant={'h4'}>Warranty Disclaimer</Typography>

      <Typography paragraph>We make every effort to ensure that Clippitt operates as intended, but unforeseen circumstances may arise. Please note that Clippitt is provided "as is" and without any form of warranty. Any implied or express warranties, including merchantability, fitness for a specific purpose, and non-infringement are excluded to the maximum extent allowed by law. This disclaimer also extends to our subsidiaries, affiliates, and third-party service providers.</Typography>

      <Typography variant={'h4'}>Limitation of liability</Typography>

      <Typography paragraph>In summary: If you incur financial losses from using Clippitt, any compensation to you is capped at the amount of earnings we have made from your use of Clippitt. To the fullest extent permitted by law, we are not responsible for any indirect, consequential, or punitive damages resulting from these terms or your use or attempted use of Clippitt. To the maximum extent allowed by law, our liability for damages is restricted to the amount of earnings we have made through your use of Clippitt. We explicitly disclaim liability for loss related to unfulfilled offerings and benefits, as well as losses arising from conflicting contractual agreements. For this provision, "we" and "our" encompass our subsidiaries, affiliates, officers, directors, employees, agents, and third-party service providers.</Typography>

      <Typography variant={'h4'}>Dispute Resolution</Typography>

      <Typography paragraph>If you encounter any problems, please don't hesitate to reach out to us at support@clippitt.com. In the event of a disagreement related to these terms or your use of Clippitt, it will be addressed in United Kingdom courts under UK law. We urge you to get in touch with us if there's an issue and agree that any disputes will be handled exclusively by the courts in the UK.</Typography>

      <Typography variant={'h4'}>In summary</Typography>

      <Typography paragraph>In summary, these terms represent Clippitt's definitive policies, and we will notify you of any significant changes to them. These terms and referenced policies constitute the entire agreement between us, superseding all previous agreements, and do not establish a partnership or employment relationship. If any provision is unenforceable, it will be modified as needed; if modification isn't possible, it will be removed while leaving other provisions intact. We may update these terms from time to time. By continuing to use Clippitt after such changes take effect signifies acceptance of the new terms. Our commitment tries to include accessibility for individuals with disabilities and is being monitored for improvement. If you opt-in to receive text messages from us, consent implies receiving recurring automated promotional texts (e.g., SMS) using your provided mobile number or another designated number - this is not conditional on any purchase but standard message rates may apply. The headquarters address for Clippitt is 'Clippitt HQ', Springbank Gardens, New Mills, High Peak, Derbyshire, United Kingdom, SK22 4BJ.</Typography>
  </>
  );
}
