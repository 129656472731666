import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';

import { getViewedVideoIds, getVideoContentByIds } from 'src/firebase/documentQueries';
import { DocumentData, QueryDocumentSnapshot, getDocs } from 'firebase/firestore';
import { useSnackbar } from 'src/components/snackbar';
import VideoCard from 'src/components/video-card/VideoCard';
import { useAuthContext } from 'src/auth/useAuthContext';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const VIEWED_COUNT = 30;

export default function HistoryPage() {
  const {user} = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [videoData, setVideoData] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);

  useEffect(() => {
    const queryVideos = async () => {
      if (user) {
        const idSnapshot = getViewedVideoIds(user?.uid);
        const idDocs = await getDocs(idSnapshot);
        const ids = idDocs.docs.map(item => item.data().id).filter((value, index, self) => self.indexOf(value) === index).slice(0, VIEWED_COUNT);
        const viewed = await getVideoContentByIds(ids, VIEWED_COUNT);
        const viewedSnapshot = await getDocs(viewed);
        setVideoData(viewedSnapshot.docs.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)));
      }
    }
    queryVideos();
  }, [enqueueSnackbar, user]);

  return (
    <StyledContainer>
      <Helmet>
        <title>My recently viewed</title>
      </Helmet>

      <Typography variant={'h2'}>My recently viewed</Typography>

      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
      >
        {videoData.map((video) => (
          <VideoCard video={video.data()} videoId={video.id} key={video.id} />
        ))}
      </Box>
    </StyledContainer>
  );
}
