// @mui
import { Container, Tab, Tabs } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import LegalTerms from './legal/LegalTerms';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import ConfPolicy from './legal/ConfPolicy';
import ConfPromise from './legal/ConfPromise';
import CommGuidelines from './legal/CommGuidelines';
import UserGuidelines from './legal/UserGuidelines';
import SecurityPolicy from './legal/SecurityPolicy';
import GDPRPolicy from './legal/GDPRPolicy';
import CookiePolicy from './legal/CookiePolicy';
import CopyrightPolicy from './legal/CopyrightPolicy';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function LegalPage() {
  const { themeStretch } = useSettingsContext();
  const { tab } = useParams();

  const [currentTab, setCurrentTab] = useState(tab || 'terms');

  const TABS = [
    {
      value: 'terms',
      label: 'Terms of Use',
      link: PATH_PAGE.legal + '/terms',
      component: <LegalTerms />,
    },
    {
      value: 'confidentiality-policy',
      label: 'Confidentiality Policy',
      link: PATH_PAGE.legal + '/confidentiality-policy',
      component: <ConfPolicy />,
    },
    {
      value: 'confidentiality-promise',
      label: 'Confidentiality Promise',
      link: PATH_PAGE.legal + '/confidentiality-promise',
      component: <ConfPromise />,
    },
    {
      value: 'community-guidelines',
      label: 'Community Guidelines',
      link: PATH_PAGE.legal + '/community-guidelines',
      component: <CommGuidelines />,
    },
    {
      value: 'user-guidelines',
      label: 'User Guidelines',
      link: PATH_PAGE.legal + '/user-guidelines',
      component: <UserGuidelines />,
    },
    {
      value: 'security-policy',
      label: 'Security Policy',
      link: PATH_PAGE.legal + '/security-policy',
      component: <SecurityPolicy />,
    },
    {
      value: 'gdpr',
      label: 'GDPR Policy',
      link: PATH_PAGE.legal + '/gdpr',
      component: <GDPRPolicy />,
    },
    {
      value: 'cookie-policy',
      label: 'Cookie Policy',
      link: PATH_PAGE.legal + '/cookie-policy',
      component: <CookiePolicy />,
    },
    {
      value: 'copyright-policy',
      label: 'Copyright Policy',
      link: PATH_PAGE.legal + '/copyright-policy',
      component: <CopyrightPolicy />,
    },
  ];

  return (
    <StyledContainer>
      <CustomBreadcrumbs
        heading="Legal"
        links={[
          { name: 'Home', href: PATH_DASHBOARD.root },
          { name: 'Legal' },
        ]}
      />

      <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} component={Link} to={tab.link} />
        ))}
      </Tabs>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </StyledContainer>
  );
}
