import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import {
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updatePassword,
  reauthenticateWithCredential,
  User as AuthUser,
  getAuth,
  EmailAuthProvider,
} from 'firebase/auth';
// config
//
import { ActionMapType, AuthStateType, FirebaseContextType } from './types';
import { auth } from '../firebase';
import { ProfileStore, UserStore } from 'src/firebase/firestore';
import { Profile, User, UserRole } from 'src/firebase/interfaces';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  UPDATE_USER = 'UPDATE_USER',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    role?: UserRole;
    user?: AuthUser & User;
    profile?: Profile;
  };
  [Types.UPDATE_USER]: {
    user: AuthUser & User;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        ...state,
        isInitialized: true,
        ...action.payload
      };
    case Types.UPDATE_USER:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | undefined>(undefined);

// ----------------------------------------------------------------------

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUserData = async (user: AuthUser | null) => {
    if (user) {
      const token = await user.getIdTokenResult();
      const claims = token.claims;
      const userstore = new UserStore(user.uid);
      const userdoc = await userstore.get();
      const profilestore = new ProfileStore(user.uid);
      const profiledoc = await profilestore.get();

      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: true,
          role: claims?.role || 'user',
          user: {
            ...user,
            ...userdoc.data(),
          },
          profile: profiledoc.data(),
        },
      });
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: undefined,
          profile: undefined
        },
      });
    }
  }

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(auth, async (user) => {
        fetchUserData(user);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  }, []);

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(auth, GOOGLE_PROVIDER);
  }, []);

  const loginWithGithub = useCallback(() => {
    signInWithPopup(auth, GITHUB_PROVIDER);
  }, []);

  const loginWithTwitter = useCallback(() => {
    signInWithPopup(auth, TWITTER_PROVIDER);
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string, dob: Date, isAdult: boolean) => {
      await createUserWithEmailAndPassword(auth, email, password).then(async (res) => {
        const user = new UserStore(res.user?.uid);
        await user.set({
          firstName,
          lastName,
          email,
          dob,
          isAdult,
        });
      });
    },
    []
  );

  // UPDATE USER PASSWORD
  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      const auth = getAuth();
      if (auth.currentUser && auth.currentUser.email) {
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          oldPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential).then(async (res) => {
          if (res.user) {
            await updatePassword(res.user, newPassword);
          }
        });
      }
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    return signOut(auth);
  }, []);

  const updateUser = useCallback((user: User & AuthUser) => {
    dispatch({
      type: Types.UPDATE_USER,
      payload: {
        user
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      profile: state.profile,
      role: state.role,
      method: 'firebase',
      login,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      register,
      changePassword,
      logout,
      updateUser,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.profile,
      state.role,
      login,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      register,
      changePassword,
      logout,
      updateUser,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
