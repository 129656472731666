import _mock from '../_mock';
import { randomInArray } from '../utils';

// ----------------------------------------------------------------------

export const _carouselsMembers = [...Array(6)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  role: _mock.role(index),
  avatar: `/assets/images/portraits/portrait_${index + 1}.jpg`,
}));

// ----------------------------------------------------------------------

export const _faqs = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Questions ${index + 1}`,
  detail: _mock.text.description(index),
}));

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  receiver: _mock.name.fullName(index),
  fullAddress: _mock.address.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  addressType: index === 0 ? 'Home' : 'Office',
  isDefault: index === 0,
}));

// ----------------------------------------------------------------------

export const _skills = [...Array(3)].map((_, index) => ({
  label: ['Development', 'Design', 'Marketing'][index],
  value: _mock.number.percent(index),
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  username: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  address: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  email: _mock.email(index),
  lastActivity: _mock.time(index),
  status: randomInArray(['online', 'offline', 'away', 'busy']),
  role: _mock.role(index),
}));

// ----------------------------------------------------------------------

export const _notifications = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    'Robert Crutchley',
    'Sylvan King',
    'You have a new message',
    'Andy Smith',
    'Your video',
  ][index],
  description: [
    'uploaded a new video',
    'replied to your comment',
    '5 unread messages',
    'subscribed to your channel',
    'received over 500,000 views',
  ][index],
  avatar: [_mock.image.avatar(1), _mock.image.avatar(2), null, _mock.image.avatar(3), _mock.image.avatar(5)][index],
  type: ['friend_interactive', 'friend_interactive', 'chat_message', 'friend_interactive', 'friend_interactive'][index],
  createdAt: _mock.time(index),
  isUnRead: [true, true, false, false, false][index],
}));

// ----------------------------------------------------------------------

export const _mapContact = [
  {
    latlng: [33, 65],
    address: _mock.address.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  },
  {
    latlng: [-12.5, 18.5],
    address: _mock.address.fullAddress(2),
    phoneNumber: _mock.phoneNumber(2),
  },
];

// ----------------------------------------------------------------------

export const _socials = [
  {
    value: 'youtube',
    name: 'YouTube',
    icon: 'ri:youtube-fill',
    color: '#1877F2',
    path: 'https://www.youtube.com/@clippittcreators',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ri:instagram-fill',
    color: '#E02D69',
    path: 'https://www.instagram.com/clippitt_content_creators/',
  },
  {
    value: 'tiktok',
    name: 'TikTok',
    icon: 'ri:tiktok-fill',
    color: '#007EBB',
    path: 'https://www.tiktok.com/@clippitt.com',
  },
  {
    value: 'facebook',
    name: 'Facebook',
    icon: 'ri:facebook-circle-fill',
    color: '#00AAEC',
    path: 'https://www.facebook.com/clippittcreators',
  },
];
