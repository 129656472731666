export const videoThumbnails = [
  '/assets/mock-thumbnails/pexels-bonus-studio-4768726-3840x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-cityxcape-3933471-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-denys-gromov-4632265-3840x2160-25fps.jpg',
  '/assets/mock-thumbnails/pexels-denys-gromov-4634388-3840x2160-25fps.jpg',
  '/assets/mock-thumbnails/pexels-free-videos-853848-1920x1080-25fps.jpg',
  '/assets/mock-thumbnails/pexels-george-morina-5330839-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-hans-muggesen-1722591-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-jesus-cambronero-15488290-2560x1440-25fps.jpg',
  '/assets/mock-thumbnails/pexels-kelly-2882090-3840x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-kelly-3066447-4096x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-kelly-3066451-4096x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-mike-bird-2110972-3840x2160-60fps.jpg',
  '/assets/mock-thumbnails/pexels-ojyrai-films-7727415-1920x1080-50fps.jpg',
  '/assets/mock-thumbnails/pexels-ojyrai-films-7727416-1920x1080-50fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-854225-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-854233-1280x720-29fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-854749-1280x720-25fps.jpg',
  '/assets/mock-thumbnails/pexels-super-lunar-4835989-1920x1080-25fps.jpg',
  '/assets/mock-thumbnails/pexels-taryn-elliott-5309435-1920x1080-25fps.jpg',
  '/assets/mock-thumbnails/pexels-westarmoney-5673623-1920x1080-60fps.jpg',
];

export const videoIds = [
  6331834436112,
  6331832798112,
  6331822396112,
  6331833376112,
  6331837866112,
  6331835725112,
  6331835315112,
  6331835638112,
  6331837011112,
  6331836794112,
  6331831793112,
  6331837777112,
  6331833767112,
  6331833977112,
  6331837382112,
  6331837016112,
  6331835319112,
  6331836910112,
  6331835525112,
  6331833865112,
];

export const creatorIds = [
  6331834436112,
  6331832798112,
  6331822396112,
  6331833376112,
  6331837866112,
  6331835725112,
  6331835315112,
  6331835638112,
  6331837011112,
  6331836794112,
  6331831793112,
  6331837777112,
  6331833767112,
  6331833977112,
  6331837382112,
  6331837016112,
  6331835319112,
  6331836910112,
  6331835525112,
  6331833865112,
];
