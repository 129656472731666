export const avatars = [
  '/assets/mock-avatars/inthewvlogs.png',
  '/assets/mock-avatars/calebwsimpson.png',
  '/assets/mock-avatars/FamilyFizz.png',
  '/assets/mock-avatars/Maaybeamy.png',
  '/assets/mock-avatars/Teacher_Tommy_T.png',
  '/assets/mock-avatars/actorsaccentcoach.png',
  '/assets/mock-avatars/lankeylukey89.png',
  '/assets/mock-avatars/sebastain_schieren.png',
  '/assets/mock-avatars/liverking.png',
  '/assets/mock-avatars/theliverqueen.png',
  '/assets/mock-avatars/detectivefile.png',
  '/assets/mock-avatars/dexerto.png',
  '/assets/mock-avatars/cocosarel.png',
  '/assets/mock-avatars/BashtheEntertainer.png',
  '/assets/mock-avatars/caitlinandleah.png',
  '/assets/mock-avatars/hollymurraymakeup.png',
  '/assets/mock-avatars/Hana_Martin.png',
  '/assets/mock-avatars/mammybanter.png',
  '/assets/mock-avatars/victorkunda.jpg',
  '/assets/mock-avatars/sugarcoatedsisters.jpg'
];