// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function CopyrightPolicy() {
  return (
    <>
      <Helmet>
        <title>Copyright Policy</title>
      </Helmet>
      <Typography variant={'h3'}>Copyright Policy</Typography>
      <Typography paragraph>Clippitt offers a range of services to cater to various customers and providers. The diverse nature of these services requires unique strategies for addressing claims of copyright infringement:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Clippitt offers a platform that fosters a community where creators and their supporters come together to provide assistance. The focus is on creating an environment that supports the creator, rather than sharing or transmitting creative material. Clippitt's network only handles payment, identity, and relationship information, without involving the transmission of any actual creative content. Furthermore, we do not entertain claims of copyright infringement under UK law for activities occurring outside our system or network. Additionally, subscribers accused of direct infringements outside our network are not subject to our repeat infringer termination policy." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Clippitt also offers a platform that enables creators and members to save content on our system for other users to access. Essentially, this is a hosting service where material is stored on our network upon the direction of our users. We acknowledge notifications of alleged copyright infringement concerning material stored on our system in compliance with UK law, and we enforce our policy to terminate repeat infringers among creators and members who utilise this aspect of our service." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Clippitt's technology makes it easy for our creators and members to add hyperlinks or other information location tools (sharing services) on their pages or posts within our system. We enforce UK copyright laws and hold accountable any creators or users who contribute infringing content." />
        </ListItem>
      </List>

      <Typography paragraph>If a copyright holder or its representative suspects that Clippitt's service or network hosts or transmits unauthorised material, they should seek legal counsel to understand the process and criteria for filing a copyright infringement notification. If you are the rightful owner of copyrighted content stored on Clippitt's system, and genuinely believe it infringes your rights, please send an official notification of claimed copyright infringement to support@clippitt.com via email.</Typography>
      <Typography paragraph>To ensure your claim is handled appropriately by Clippitt, please use the above process for situations involving revenge content. We will work to resolve these reports quickly and efficiently. If you are concerned about your information being forwarded, consider using an agent to handle submitting notifications of claimed copyright infringement.</Typography>

      <Typography variant={'h5'}>Trademark Policy</Typography>
      <Typography paragraph>A trademark is a unique name, phrase, slogan, strapline, or symbol that is used to identify the products or services of an individual or company. For instance, you are currently utilising the Clippitt platform. The Clippitt trademark plays a vital role in distinguishing this website from other online platforms. If there are any concerns about potential misuse of your trademark leading to public confusion or deception, please reach out to us at support@clippitt.com. Upon receiving your query, we will carefully assess it and take appropriate action which may include removing the disputed content from Clippitt.</Typography>
  </>
  );
}
