import { ContentType } from "src/firebase/interfaces";

export const videoUrls = [
  '/assets/mock-videos/pexels-bonus-studio-4768726-3840x2160-24fps.mp4',
  '/assets/mock-videos/pexels-cityxcape-3933471-1920x1080-30fps.mp4',
  '/assets/mock-videos/pexels-denys-gromov-4632265-3840x2160-25fps.mp4',
  '/assets/mock-videos/pexels-denys-gromov-4634388-3840x2160-25fps.mp4',
  '/assets/mock-videos/pexels-free-videos-853848-1920x1080-25fps.mp4',
  '/assets/mock-videos/pexels-george-morina-5330839-1920x1080-30fps.mp4',
  '/assets/mock-videos/pexels-hans-muggesen-1722591-1920x1080-30fps.mp4',
  '/assets/mock-videos/pexels-jesus-cambronero-15488290-2560x1440-25fps.mp4',
  '/assets/mock-videos/pexels-kelly-2882090-3840x2160-24fps.mp4',
  '/assets/mock-videos/pexels-kelly-3066447-4096x2160-24fps.mp4',
  '/assets/mock-videos/pexels-kelly-3066451-4096x2160-24fps.mp4',
  '/assets/mock-videos/pexels-mike-bird-2110972-3840x2160-60fps.mp4',
  '/assets/mock-videos/pexels-ojyrai-films-7727415-1920x1080-50fps.mp4',
  '/assets/mock-videos/pexels-ojyrai-films-7727416-1920x1080-50fps.mp4',
  '/assets/mock-videos/pexels-pixabay-854225-1920x1080-30fps.mp4',
  '/assets/mock-videos/pexels-pixabay-854233-1280x720-29fps.mp4',
  '/assets/mock-videos/pexels-pixabay-854749-1280x720-25fps.mp4',
  '/assets/mock-videos/pexels-pixabay-856246-1920x1080-24fps.mp4',
  '/assets/mock-videos/pexels-pixabay-856298-1920x1080-30fps.mp4',
  '/assets/mock-videos/pexels-produtora-midtrack-1899146-1920x1080-30fps.mp4',
  '/assets/mock-videos/pexels-super-lunar-4835989-1920x1080-25fps.mp4',
  '/assets/mock-videos/pexels-taryn-elliott-5309435-1920x1080-25fps.mp4',
  '/assets/mock-videos/pexels-twicce-switzerland-5617135-3840x2160-60fps.mp4',
  '/assets/mock-videos/pexels-westarmoney-5673623-1920x1080-60fps.mp4',
];

export const videoThumbnails = [
  '/assets/mock-thumbnails/pexels-bonus-studio-4768726-3840x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-cityxcape-3933471-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-denys-gromov-4632265-3840x2160-25fps.jpg',
  '/assets/mock-thumbnails/pexels-denys-gromov-4634388-3840x2160-25fps.jpg',
  '/assets/mock-thumbnails/pexels-free-videos-853848-1920x1080-25fps.jpg',
  '/assets/mock-thumbnails/pexels-george-morina-5330839-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-hans-muggesen-1722591-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-jesus-cambronero-15488290-2560x1440-25fps.jpg',
  '/assets/mock-thumbnails/pexels-kelly-2882090-3840x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-kelly-3066447-4096x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-kelly-3066451-4096x2160-24fps.jpg',
  '/assets/mock-thumbnails/pexels-mike-bird-2110972-3840x2160-60fps.jpg',
  '/assets/mock-thumbnails/pexels-ojyrai-films-7727415-1920x1080-50fps.jpg',
  '/assets/mock-thumbnails/pexels-ojyrai-films-7727416-1920x1080-50fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-854225-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-854233-1280x720-29fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-854749-1280x720-25fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-856246-1920x1080-24fps.jpg',
  '/assets/mock-thumbnails/pexels-pixabay-856298-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-produtora-midtrack-1899146-1920x1080-30fps.jpg',
  '/assets/mock-thumbnails/pexels-super-lunar-4835989-1920x1080-25fps.jpg',
  '/assets/mock-thumbnails/pexels-taryn-elliott-5309435-1920x1080-25fps.jpg',
  '/assets/mock-thumbnails/pexels-twicce-switzerland-5617135-3840x2160-60fps.jpg',
  '/assets/mock-thumbnails/pexels-westarmoney-5673623-1920x1080-60fps.jpg',
];

export const videoTypes: ContentType[] = [
  ContentType.Purchase,
  ContentType.Subscription,
  ContentType.Free,
  ContentType.Purchase,
  ContentType.Free,
  ContentType.Free,
  ContentType.Subscription,
  ContentType.Free,
  ContentType.Free,
  ContentType.Purchase,
  ContentType.Free,
  ContentType.Subscription,
  ContentType.Purchase,
  ContentType.Free,
  ContentType.Free,
  ContentType.Subscription,
  ContentType.Subscription,
  ContentType.Free,
  ContentType.Free,
  ContentType.Free,
  ContentType.Subscription,
  ContentType.Purchase,
  ContentType.Free,
  ContentType.Subscription,
]