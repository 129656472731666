import { Link } from '@mui/material';
import { CSSProperties } from 'react';
import CookieConsent from "react-cookie-consent";

const cookieStyle: CSSProperties = {
  background: '#323C46',
  borderRadius: 10,
  marginBottom: 15,
  width: 'auto',
  left: 10,
  right: 10,
  zIndex: 2147483640,
};

const cookieButtonStyle: CSSProperties = {
  background: '#4BFEFB',
  borderRadius: 10,
  color: 'black',
  textTransform: 'uppercase',
  marginRight: 0,
  border: 0,
  alignSelf: 'flex-end',
};

const cookieDeclineButtonStyle: CSSProperties = {
  background: 'rgba(255,255,255,0.1)',
  borderRadius: 10,
  color: 'white',
  textTransform: 'uppercase',
  border: 0,
};

export default function Cookies() {
  return (
    <CookieConsent 
      style={cookieStyle}
      buttonStyle={cookieButtonStyle}
      declineButtonStyle={cookieDeclineButtonStyle}
      flipButtons={true}
      buttonText={'Allow All'}
      declineButtonText={'Essential Only'}
      enableDeclineButton
    >
      Clippitt uses essential <Link href={'https://en.wikipedia.org/wiki/HTTP_cookie'} target={'_blank'} rel={'noreferrer'}>Cookies</Link> to make our site work. 
      We'd also like to set analytics, and marketing cookies. These will only be set if you allow them.
    </CookieConsent>
  );
}
