// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.brightcove-react-player-loader, .video-js': {
          width: '100%',
          height: '100%',
          borderRadius: isMobile ? 0 : 10,
        },
        '.socialShareBackdropFadeIn': {
          justifyContent: 'center !important',
          '.socialSharePopupMoveIn': {
            borderRadius: '0.5rem',
            'button:last-child': {
              borderBottomLeftRadius: '0.5rem',
              borderBottomRightRadius: '0.5rem'
            }
          }
        },
        'div.swiper-button-prev, div.swiper-button-next': {
          color: theme.palette.primary.main,
          marginTop: -55,
        },
        'div.swiper': {
          paddingBottom: 50,
        },
        'div.swiper-home': {
          marginLeft: isMobile ? -10 : 0,
          marginRight: isMobile ? -10 : 0,
        },
        'span.swiper-pagination-bullet': {
          backgroundColor: theme.palette.primary.main,
        },
        'div.swiper-slide': {
          borderRadius: isMobile ? 0 : 10,
          overflow: 'hidden',
        }
      }}
    />
  );

  return inputGlobalStyles;
}
