import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  FirestoreError,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  UpdateData,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { firestore } from ".";
import { CheckoutSession, FirestoreCollection, User, Content, Profile } from "./interfaces";

const converter = <T>(): FirestoreDataConverter<T> => ({
  toFirestore: (data: PartialWithFieldValue<T>) => data as DocumentData,
  fromFirestore: (snap: QueryDocumentSnapshot<DocumentData>) =>
    snap.data() as T,
});

class Store<T> {
  id: string;
  private collection: CollectionReference<T>;
  private doc: DocumentReference<T>;

  constructor(id: string, path: string) {
    this.id = id;
    this.collection = collection(firestore, path).withConverter(converter<T>());
    this.doc = doc(this.collection, id);
  }

  get = async () => {
    const doc = await getDoc(this.doc);
    return doc;
  }

  set = async (data: WithFieldValue<T>, merge: boolean = false) => {
    await setDoc(this.doc, data, {merge});
  }

  update = async (data: UpdateData<T>) => {
    await updateDoc(this.doc, data);
  }

  listen = (onNext: (snapshot: DocumentSnapshot<T>) => void, onError?: (error: FirestoreError) => void) => {
    return onSnapshot(this.doc, onNext, onError);
  }
}

export class UserStore extends Store<User> {
  constructor(id: string) {
    super(id, FirestoreCollection.User);
  }
}

export class ProfileStore extends Store<Profile> {
  constructor(id: string) {
    super(id, FirestoreCollection.Profile);
  }
}

export class CheckoutStore extends Store<CheckoutSession> {
  constructor(userId: string, id: string) {
    const path = [FirestoreCollection.User, userId, FirestoreCollection.CheckoutSession].join('/');
    super(id, path);
  }
}

export class ContentStore extends Store<Content> {
  constructor(id: string) {
    super(id, FirestoreCollection.Content);
  }
}
