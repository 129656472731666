import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';

import { getVideoContentByIds, getFavouriteVideoIds } from 'src/firebase/documentQueries';
import { DocumentData, QueryDocumentSnapshot, getDocs } from 'firebase/firestore';
import { useSnackbar } from 'src/components/snackbar';
import VideoCard from 'src/components/video-card/VideoCard';
import { useAuthContext } from 'src/auth/useAuthContext';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const FAVOURITES_COUNT = 30;

export default function FavouritesPage() {
  const {user, isInitialized} = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = useState(false);
  const [videoData, setVideoData] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);

  useEffect(() => {
    const queryVideos = async () => {
      if (user) {
        const idSnapshot = getFavouriteVideoIds(user?.uid);
        const idDocs = await getDocs(idSnapshot);
        const ids = idDocs.docs.map(item => item.id).slice(0, FAVOURITES_COUNT);
        if (ids.length) {
          const viewed = await getVideoContentByIds(ids, FAVOURITES_COUNT);
          const viewedSnapshot = await getDocs(viewed);
          setVideoData(viewedSnapshot.docs.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)));
        }
      }

      if (isInitialized) {
        setLoaded(true);
      }
    }
    queryVideos();
  }, [enqueueSnackbar, isInitialized, user]);

  return (
    <StyledContainer>
      <Helmet>
        <title>My Favourites</title>
      </Helmet>

      <Typography variant={'h2'}>My Favourites</Typography>

      {loaded && (
        videoData.length ? (
          <Box
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
          >
            {videoData.map((video) => <VideoCard video={video.data()} videoId={video.id} key={video.id} />)}
          </Box>
        ) : (
          <Typography>Your favourites will appear here. Go favourite some content!</Typography>
        )
      )}
    </StyledContainer>
  );
}
