// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function SecurityPolicy() {
  return (
    <>
      <Helmet>
        <title>Security Policy</title>
      </Helmet>
      <Typography variant={'h3'}>Security Policy</Typography>
      <Typography variant={'h4'}>What we do</Typography>
      <Typography paragraph>We ensure that your complete credit card details are never accessed by our systems, and we employ robust encryption for the most critical information. Additionally, we're preparing to introduce enhanced security features including two-factor authentication for logging in and CAPTCHA verification when suspicious activity is detected, such as login attempts from a questionable IP address or rapid consecutive logins. It's important to note that all data exchanged between you and us is encrypted through HTTPS exclusively.</Typography>

      <Typography variant={'h4'}>What you should do</Typography>
      <Typography paragraph>As a creator or member, it is crucial to choose a strong and unique password for your account's security. You can store this password in the 'Security' section of the 'My Account' settings. If you have any concerns about the safety of your personal information, feel free to reach out to us at support@clippitt.com. For those knowledgeable about security matters, if you believe that there may be a security-related issue with Clippitt, we value responsible disclosure and are open to rewarding bug reports. Additionally, we welcome feedback aimed at enhancing our platform and offer monthly rewards for the best ideas. All bug reports and feedback should be emailed to support@clippitt.com; rest assured that we will respond promptly. When submitting bug reports, please provide a detailed description of the issue along with steps required for replication. For sensitive issues marked as 'Private and Sensitive' in the email title; these messages will only be visible by our CEO and Founder.</Typography>

      <Typography variant={'h4'}>What you should not do</Typography>
      <Typography paragraph>Please refrain from participating in any actions that put the security of our website and users at risk. Here are a few examples of behaviours to avoid on Clippitt.</Typography>
      
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Illegal Activities - Don't break the law or encourage others to break the law." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Personal Information - Please refrain from sharing or misusing others' personal information. Creators who have access to their members' personal details should only use it for relevant Clippitt-related purposes." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Spam - Don't spam others or distribute unsolicited advertising material." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Malware - Don't use Clippitt to host or distribute malicious or destructive software." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Service Degradation - Don't degrade others' use of Clippitt or do any kind of brute force or load testing." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Data Mining - Don't crawl, scrape or otherwise index information on Clippitt. If you are doing this to create a useful feature then we may allow it, but you must check with us first." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Reverse Engineering - Please refrain from reverse engineering or accessing our code without proper authorisation. If you are eager to contribute to the enhancement of Clippitt's security measures, we encourage you to reach out to support@clippitt.com." />
        </ListItem>
      </List>
      <Typography paragraph>We are always on the lookout for personnel with great security and bug-fixing experience and would be happy to receive a CV if we are advertising for a specific role in this area.</Typography>
      <Typography paragraph>If you participate in any of these activities or put the security of our website and users at risk, we reserve the right to terminate your account.</Typography>
  </>
  );
}
