import { Helmet } from 'react-helmet-async';
// @mui
import { Typography, Container } from '@mui/material';
import { Box, styled } from '@mui/system';
import ReactPlayerLoader from '@brightcove/react-player-loader';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function AboutPage() {

  return (
    <StyledContainer>
      <Helmet>
        <title>How it works</title>
      </Helmet>

      <Typography variant={'h2'}>How it Works</Typography>
      <Container sx={{mt: 5, mb: 10}}>
        <Box sx={{width: "100%", aspectRatio: 16/9, position: 'relative'}}>
          <ReactPlayerLoader accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID || ''} videoId={6346623435112} />
        </Box>
      </Container>
    </StyledContainer>
  );
}
