import { useEffect, useState, MouseEvent } from 'react';

// @mui
import { Box, Card, CardActionArea, CardProps, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { DocumentData, DocumentReference, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fToNow } from 'src/utils/formatTime';
import Image from '../../components/image';
import CustomAvatar from '../custom-avatar/CustomAvatar';
import { ContentType } from 'src/firebase/interfaces';

// ----------------------------------------------------------------------

interface VideoCardProps extends CardProps {
  video: DocumentData;
  videoId: string;
  layout?: 'horizontal' | 'vertical';
  creatorData?: DocumentData | undefined;
};

export default function VideoCard({ video, videoId, layout='vertical', creatorData = undefined, ...props }: VideoCardProps) {

  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);
  const [creator, setCreator] = useState<DocumentData | undefined>(creatorData);

  const onClickViewContentPage = () => {
    navigate(PATH_DASHBOARD.general.videoDetails(videoId));
  };

  const showPlayIcon = (e: any) => {
    e.preventDefault();
    setDisplay(true);
  };

  const hidePlayIcon = (e: any) => {
    e.preventDefault();
    setDisplay(false);
  };

  const navigateToCreator = (e: MouseEvent<HTMLDivElement> ) => {
    e.stopPropagation();
    navigate(PATH_DASHBOARD.channel.channelDetails(video.creator.id));
  };

  const fetchCreatorData = async (creatorRef: DocumentReference) => {
    const creator = await getDoc(creatorRef);
    const creatorDocData = creator.data();
    setCreator(creatorDocData);
  }

  useEffect(() => {
    if (!creator && video.creator) {
      fetchCreatorData(video.creator);
    }
  }, [creator, video.creator]);

  if (layout === 'horizontal')
    return (
      <Card key={video.id} {...props}>
        <CardActionArea key={video.id} onClick={onClickViewContentPage} onMouseEnter={showPlayIcon} onMouseLeave={hidePlayIcon}>
          <Grid container spacing={2} justifyContent='center' alignItems='flex-start'>
            <Grid item xs={5}>
              <Box display='flex' alignItems='center' height='100%' sx={{position: 'relative'}}>
                <Image alt={video.title} src={video.videoThumbnail} ratio='16/9' sx={{ borderRadius: 1 }} />
                <PlayCircleIcon color='primary' sx={{ opacity: display ? 1 : 0, position: 'absolute', top: '50%', left: '50%', marginLeft: '-20px', marginTop: '-20px', height: '40px', width: '40px' }} />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Stack width="100%" sx={{p: 1.5, pl: 0}}>
                <Typography variant='body1'>{video.title}</Typography>
                <Typography variant="caption">{creator?.name}</Typography>
                <Box>
                  <Typography variant="caption" display="inline">{`${video?.likes} likes • `}</Typography>
                  <Typography variant="caption" display="inline">{`${video?.views} views • `}</Typography>
                  <Typography variant="caption" display="inline">{` ${fToNow(video?.updatedAt?.toDate() || video?.createdAt?.toDate())}`}</Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </CardActionArea>
        {video.type !== ContentType.Free && (
          <Iconify icon="ant-design:pound-circle-filled" width={30} height={30} sx={{color: 'primary.main', position: 'absolute', right: 10, bottom: 10}} />
        )}
      </Card>
    );
  else
    return (
      <Card key={video.id} {...props}>
        <CardActionArea onClick={onClickViewContentPage} onMouseEnter={showPlayIcon} onMouseLeave={hidePlayIcon}>
          <Box sx={{ position: 'relative', p: 1 }}>
            <Image alt={video.title} src={video.videoThumbnail} ratio='16/9' sx={{ borderRadius: 1 }} />
            <PlayCircleIcon color='primary' sx={{ display: display ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', marginLeft: '-25px', marginTop: '-25px', height: '50px', width: '50px' }} />
          </Box>
    
          <Stack spacing={1} sx={{ p: 1 }}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                minHeight: 48,
              }}
            >
              {video.title}
            </Typography>
            <Box display="flex" flexDirection="row" mr={5} onClick={navigateToCreator} sx={{ margin: '8px -8px -8px -8px !important', padding: '8px', '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: '20px' } }}>
              <CustomAvatar alt={creator?.name} src={creator?.avatar} sx={{marginRight: 1}} />
              <Box>
                {creator ? (
                  <Typography variant="body1">{creator?.name}</Typography>
                ) : (
                  <Skeleton height='24px' />
                )}
                <Typography variant="caption" display="inline">{`${video?.likes} likes • `}</Typography>
                <Typography variant="caption" display="inline">{`${video?.views} views • `}</Typography>
                <Typography variant="caption" display="inline">{` ${fToNow(video?.updatedAt?.toDate() || video?.createdAt?.toDate())}`}</Typography>
              </Box>
            </Box>
          </Stack>
          {video.type !== ContentType.Free && (
            <Iconify icon="ant-design:pound-circle-filled" width={30} height={30} sx={{color: 'primary.main', position: 'absolute', right: 10, bottom: 10}} />
          )}
        </CardActionArea>
      </Card>
    );
}
