import {Timestamp} from 'firebase/firestore';

export enum FirestoreCollection {
  User = 'users',
  Profile = 'profiles',
  Content = 'content',
  CheckoutSession = 'checkout_sessions'
}

export enum FirestoreOperator {
  Order = 'order',
  Active = 'active',
  Created = 'createdAt',
  Updated = 'updatedAt',
  Status = 'status'
}

export enum FirebaseStoragePath {
  User = 'users'
}

export enum FirebaseStorageType {
  Avatar = 'avatar',
  Cover = 'cover',
  MobileCover = 'mobilecover',
  Poster = 'poster'
}

export enum SocialService {
  Facebook = 'facebook',
  Twitter = 'twitter',
  TikTok = 'tiktok',
  YouTube = 'youtube',
  Instagram = 'instagram',
  Reddit = 'reddit',
  Pinterest = 'pinterest',
  LinkedIn = 'linkedin',
  Twitch = 'twitch',
  Website = 'website',
}

export interface Service {
  service: SocialService;
  name: string;
  prepend: string;
}

export interface SocialLink {
  service?: SocialService;
  handle: string;
}

export enum UserStatus {
  Active = 'active',
  Disabled = 'disabled',
  Deleted = 'deleted',
  Pending = 'pending'
}

export enum UserRole {
  Admin = 'admin',
  User = 'user'
}

export interface User {
  firstName?: string;
  lastName?: string;
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  email?: string | null;
  dob?: Date;
  isAdult?: boolean;
}

export interface EditableProfile {
  name: string;
  location: string;
  bio: string;
  avatar: string;
  cover: string;
  mobilecover: string;
  links: SocialLink[];
}

export interface Profile extends EditableProfile {
  isCreator?: boolean;
  price?: number;
  interval?: SubscriptionInterval;
  frequency?: number;
  benefits?: string;
  handle?: string;
  followers?: number;
  subscribers?: number;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  onlineAt: Timestamp;
}

export enum ContentType {
  Free = 'free',
  Subscription = 'subscription',
  Purchase = 'purchase'
}

export enum MediaType {
  Video = 'video',
  Trailer = 'trailer',
}

export interface UploadContent {
  video: File;
}

export interface EditableContent {
  description: string;
  title: string;
  type: ContentType;
  price?: number;
}

export enum Status {
  Error = 'error',
  Active = 'active',
  Draft = 'draft',
  Deleted = 'deleted',
  Pending = 'pending',
}

export interface Content extends EditableContent {
  contentId: string;
  creator: string;
  duration: number;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: Status;
  error?: string;
  views?: number;
  purchases?: number;
  likes?: number;
  favourites?: number;
  videoId?: number;
  videoStatus?: Status;
  trailerId?: number;
  trailerStatus?: Status;
}

export enum SubscriptionInterval {
  Yearly = 'yearly',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export interface SubscriptionSettings {
  interval: SubscriptionInterval;
  frequency: number;
  price: number;
  benefits?: string;
}

export enum CheckoutType {
  Subscription = 'subscription',
  Purchase = 'purchase'
}

export interface CheckoutSessionError {
  message: string;
}

export interface CheckoutSession {
  entityId: string;
  mode: string;
  url?: string;
  error?: CheckoutSessionError;
}

export interface UploadUrl {
  accountId: string;
  videoId: string;
  contentId: string;
  bucket: string;
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  objectKey: string;
  apiRequestUrl: string;
  region: string;
}

interface PaymentMetaData {
  id: string;
  name: string;
  thumbnail: string;
  type: CheckoutType;
}

export enum SubscriptionStatus {
  All = 'all',
  Active = 'active',
  Canceled = 'canceled',
}

interface SubscriptionPlan {
  active: boolean;
  amount: number;
  amount_decimal: number;
  billing_scheme: string;
  created: number;
  currency: 'gbp';
  id: string;
  interval: 'month';
  interval_count: number;
  livemode: boolean;
}

interface SubscriptionItem {
  created: number;
  id: string;
  object: string;
  plan: SubscriptionPlan;
}

export interface Subscription {
  status: SubscriptionStatus;
  created: Timestamp;
  metadata: PaymentMetaData;
  cancel_at?: Timestamp;
  cancel_at_period_end?: boolean;
  canceled_at?: Timestamp;
  current_period_end?: Timestamp;
  current_period_start?: Timestamp;
  items: SubscriptionItem[];
}

export interface Subscriber {
  status: SubscriptionStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export enum PaymentStatus {
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface Payment {
  status: PaymentStatus;
  amount: number;
  amount_capturable: number;
  amount_received: number;
  created: number;
  currency: 'gbp';
  metadata: PaymentMetaData;
}

export interface UploadJob {
  id: string;
}

export interface PublishJob {
  status: 'SUCCESS' | 'ERROR';
  error?: string;
}

export interface SubscriptionJob {
  status: 'SUCCESS' | 'ERROR';
  error?: string;
}

export interface PortalLink {
  configuration: string;
  created: number;
  customer: string;
  flow: string;
  id: string;
  livemode: boolean;
  locale: string;
  object: string;
  return_url: string;
  url: string;
}
