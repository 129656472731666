import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, Button, Alert, List, ListItem, ListItemText, InputAdornment, AlertTitle, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { DocumentSnapshot, FirestoreError, Unsubscribe } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from '../../../components/hook-form';
import { EditableContent, ContentType, Content, Status, MediaType } from 'src/firebase/interfaces';
import { Schema, object } from 'yup';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ContentStore } from 'src/firebase/firestore';
import { publishContent } from 'src/firebase/functions';
import { fToNow } from 'src/utils/formatTime';
import UploadContentDialog from 'src/components/uploadContentDialog/uploadContentDialog';

// ----------------------------------------------------------------------

export default function ContentEdit() {
  const { contentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [content, setContent] = useState<Content | undefined>();
  const [liveContent, setLiveContent] = useState<Content | undefined>();
  const [isPublishing, setIsPublishing] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);

  const allowPublish = () => {
    if (!liveContent) return false;
    if (liveContent.status !== Status.Draft) return false;
    if (liveContent.status === Status.Draft && liveContent.type === ContentType.Free && liveContent.videoStatus !== Status.Active) return false;
    if (liveContent.status === Status.Draft && liveContent.type !== ContentType.Free && (liveContent.videoStatus !== Status.Active || liveContent.trailerStatus !== Status.Active)) return false;
    return true;
  }

  let unsubscribe = useRef<Unsubscribe>();
  
  const navigate = useNavigate();

  const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  const UpdateMediaSchema: Schema<EditableContent> = object({
    title: Yup.string().required('Title is required').min(10).max(100),
    description: Yup.string().required('Description is required'),
    type: Yup.string().oneOf(Object.values(ContentType)).required('You must specify a type'),
    price: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).when('type', {
      is: ContentType.Purchase,
      then: (schema) => schema
      .required('Price is required when content is a purchase')
      .typeError('Price must be a number')
      .positive()
      .test(
        'is-decimal',
        'Please enter a valid price',
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .min(1.49, 'Minimum price should be £1.49')
      .max(49.99, 'Maximum price should be £49.99'),
    })
  });

  const defaultValues: EditableContent = {
    title: content?.title || '',
    description: content?.description || '',
    type: content?.type || ContentType.Purchase,
    price: content?.price || 0,
  };

  const methods = useForm<EditableContent>({
    resolver: yupResolver(UpdateMediaSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid, isDirty },
  } = methods;

  const type = watch('type');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadOpen = () => {
    setUploadOpen(true);
  };

  const handleUploadClose = () => {
    setUploadOpen(false);
  };

  const onNext = (snapshot: DocumentSnapshot<Content>) => {
    setLiveContent(snapshot.data());
  };

  const onError = (error: FirestoreError) => {
    console.log('Error', error);
  }

  useEffect(() => {
    const getContent = async () => {
      if (contentId) {
        const store = new ContentStore(contentId);
        const document = await store.get();
        const data = document.data();
        setContent(data);
        setLiveContent(data);
        if (data?.status === Status.Draft) {
          unsubscribe.current = store.listen(onNext, onError);
        }
      }
    };
    getContent();
    return () => {
      unsubscribe.current?.();
    }
  }, [contentId, unsubscribe]);

  const onSubmit = async (data: EditableContent) => {
    try {
      if (contentId) {
        const store = new ContentStore(contentId);
        await store.update(data);
        enqueueSnackbar('Your content has been updated successfully');
        reset(data);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An error occurred saving your content', { variant: 'error' });
    }
  };

  const handlePublish = async () => {
    if (contentId) {
      setIsPublishing(true);
      const publishJob = await publishContent(contentId);
      switch (publishJob.status) {
        case 'SUCCESS': {
          enqueueSnackbar('Your content has been published successfully');
          break;
        }
        case 'ERROR': {
          enqueueSnackbar(`Your content could not be published: ${publishJob.error}`, { variant: 'error' });
          break;
        }
      }
      setIsPublishing(false);
      setOpen(false);
    }
  }

  useEffect(() => {
    reset(defaultValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const onCancelClick = () => {
    navigate(PATH_DASHBOARD.user.account + '/content');
  }

  const onViewClick = () => {
    if (contentId) {
      navigate(PATH_DASHBOARD.general.videoDetails(contentId));
    }
  }

  return (
      content?.status === Status.Error ? (
        <>
          <Typography variant='h6' sx={{ mb: 1 }}>{content?.title}</Typography>
          <Alert variant={'outlined'} severity='error' sx={{flex: 1}}>
            <AlertTitle>An error occurred during Processing of your Creation</AlertTitle>
            {content?.error}
          </Alert>
        </>
      ) : (
        <>
          <Typography variant='h6' sx={{ mb: 1 }}>Edit Content</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} order={{xs: 1, sm: 0}}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Card sx={{ p: 3 }}>
                  <RHFTextField name="title" label="Title" />
                  <RHFTextField name="description" multiline rows={8} label="Description" sx={{mt: 3}} />

                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                    }}
                    mt={3}
                  >
                    <RHFSelect native name="type" label="Type" placeholder="Type">
                      {Object.keys(ContentType).map((key, value) => (
                        <option key={key} value={Object.values(ContentType)[value]}>
                          {Object.values(ContentType)[value] === ContentType.Purchase ? 'Pay Per View' : key}
                        </option>
                      ))}
                    </RHFSelect>

                    {type === ContentType.Purchase && <RHFTextField name="price" label="Price" type='number' InputProps={{
                      startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }} />}
                  </Box>

                  {type !== ContentType.Free &&
                    <Alert variant={'outlined'} severity='info' sx={{flex: 1, mt: 2}}>
                      For content that requires payment or a subscription, please upload a Trailer video. 
                      This video should be used as a preview for your users to help them decide whether they 
                      wish to purchase the full content. Note that the Trailer video will be publically accessible.
                    </Alert>
                  }

                  <Stack spacing={3} direction={'row'} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button variant="outlined" color="inherit" onClick={onCancelClick}>
                      Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isDirty || content?.status !== Status.Draft}>
                      Save Changes
                    </LoadingButton>
                  </Stack>
                </Card>
              </FormProvider>

              <Typography variant='h6' sx={{ mb: 1, mt: 3 }}>Publish Content</Typography>

              <Card sx={{ p: 3 }}>
                <Stack spacing={3} direction={'row'} justifyContent="flex-end">
                  {liveContent?.status === Status.Draft && (liveContent?.videoStatus === Status.Pending || liveContent?.trailerStatus === Status.Pending) ? (
                    <Alert variant={'outlined'} severity='warning' sx={{flex: 1}}>Processing your Creation, check back in a few minutes.</Alert>
                  ) : (
                    liveContent?.status !== Status.Active && (
                      <>
                        <Alert variant={'outlined'} severity='info' sx={{flex: 1}}>Once your content is published, it cannot be deleted without contacting support.</Alert>
                        <Button variant="contained" size={'large'} onClick={handleOpen} disabled={isDirty || !isValid || !allowPublish()}>
                          Publish
                        </Button>
                      </>
                    )
                  )}
                  {liveContent?.status === Status.Active && (
                    <>
                      <Alert sx={{flex: 1}}>Your content is published and available on Clippitt.</Alert>
                      <Button variant={'outlined'} size={'large'} onClick={onViewClick}>
                        View Content
                      </Button>
                    </>
                  )}
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} order={{xs: 0, sm: 1}}>
              <Card sx={{ p: 3}}>
                <Typography variant={'subtitle1'}>Video Preview</Typography>
                <Box sx={{width: "100%", display: 'flex', aspectRatio: 16/9, backgroundColor: 'rgba(255,255,255,0.1)', justifyContent: 'center', alignItems: 'center', borderRadius: '10px'}} mt={2}>
                  {(liveContent?.status === Status.Active || liveContent?.videoStatus === Status.Active) && liveContent?.videoId ? (
                    <ReactPlayerLoader accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID || ''} videoId={liveContent?.videoId} />
                  ) : (
                    <Typography sx={{opacity: 0.5}}>{liveContent?.videoStatus === Status.Pending ? 'Processing your Creation' : 'Preview Unavailable'}</Typography>
                  )}
                </Box>
              </Card>
              
              {(type !== ContentType.Free || liveContent?.trailerId) &&
                <Card sx={{ p: 3, mt: 3}}>
                  <Typography variant={'subtitle1'} mb={2}>Trailer Preview</Typography>
                  {liveContent?.status === Status.Active || liveContent?.trailerId ? (
                    <Box sx={{width: "100%", display: 'flex', aspectRatio: 16/9, backgroundColor: 'rgba(255,255,255,0.1)', justifyContent: 'center', alignItems: 'center', borderRadius: '10px'}}>
                      {liveContent?.trailerStatus === Status.Active && liveContent?.trailerId ? (
                        <ReactPlayerLoader accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID || ''} videoId={liveContent?.trailerId} />
                      ) : (
                        <Typography sx={{opacity: 0.5}}>{liveContent?.trailerStatus === Status.Pending ? 'Processing your Trailer' : 'Preview Unavailable'}</Typography>
                      )}
                    </Box>
                  ) : (
                    <Button variant="contained" size={'large'} fullWidth onClick={handleUploadOpen}>
                      Upload Trailer
                    </Button>
                  )}
                </Card>
              }

              <Card sx={{ p: 3, mt: 3}}>
                <Typography variant={'subtitle1'}>Stats</Typography>
                <List>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <ListItemText primary={content?.views || 0} />
                    }
                  >
                    <Typography variant={'subtitle1'}>Viewed</Typography>
                  </ListItem>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <ListItemText primary={content?.likes || 0} />
                    }
                  >
                    <Typography variant={'subtitle1'}>Liked</Typography>
                  </ListItem>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <ListItemText primary={content?.favourites || 0} />
                    }
                  >
                    <Typography variant={'subtitle1'}>Favourited</Typography>
                  </ListItem>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <ListItemText primary={content?.purchases || 0} />
                    }
                  >
                    <Typography variant={'subtitle1'}>Purchased</Typography>
                  </ListItem>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <ListItemText primary={fToNow(content?.createdAt?.toDate() || Date.now())} />
                    }
                  >
                    <Typography variant={'subtitle1'}>Created</Typography>
                  </ListItem>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <ListItemText primary={fToNow(content?.updatedAt?.toDate() || Date.now())} />
                    }
                  >
                    <Typography variant={'subtitle1'}>Last Updated</Typography>
                  </ListItem>
                </List>
              </Card>
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Publish Content?</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{mb: 2}}>
                Please be aware that once your content is published, you cannot remove, edit or delete. Are you ok to publish? 
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <LoadingButton variant="contained" loading={isPublishing} onClick={handlePublish} disabled={!isValid || content?.status !== Status.Draft}>
                Publish
              </LoadingButton>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <UploadContentDialog contentId={contentId} type={MediaType.Trailer} open={uploadOpen} handleClose={handleUploadClose} />
        </>
      )
  );
}
