//
import { useState, useEffect } from 'react';
import Image from '../../image';
//
import { CustomFile } from '../types';
import ReactPlayer from 'react-player';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
  isVideo?: boolean;
};

export default function SingleFilePreview({ file, isVideo = false }: Props) {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getFileURL = async () => {
      if (typeof file === 'string') {
        setPreviewUrl(file);
      } else {
        setPreviewUrl(file?.preview);
      }
    }
  
    getFileURL();
  }, [file]);

  if (!file) {
    return null;
  }

  return (
    isVideo ? (
      <ReactPlayer
        url={previewUrl}
        width='100%'
        height='100%'
        sx={{
          top: 8,
          left: 8,
          zIndex: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      />
    ) : (
      <Image
        alt="file preview"
        src={previewUrl}
        sx={{
          top: 8,
          left: 8,
          zIndex: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      />
    )
  );
}
