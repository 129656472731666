import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// routes
import { PATH_DASHBOARD, PATH_FAVOURITES, PATH_HISTORY, PATH_LATEST, PATH_OUR_FAVOURITES, PATH_PAGE, PATH_SUGGESTED, PATH_TRENDING } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  home: icon('ic_design'),
  booking: icon('ic_booking'),
  banking: icon('ic_banking'),
  ecommerce: icon('ic_ecommerce'),
  favourite: <FavoriteBorderIcon />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'navigation',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.root, icon: ICONS.menuItem },
      { title: 'Latest Videos', path: PATH_LATEST.root, icon: ICONS.calendar },
      { title: 'Trending', path: PATH_TRENDING.root, icon: ICONS.analytics },
      { title: 'History', path: PATH_HISTORY.root, icon: ICONS.dashboard },
      { title: 'My Favourites', path: PATH_FAVOURITES.root, icon: ICONS.favourite },
      { title: 'Purchased', path: PATH_DASHBOARD.user.account + '/purchases', icon: ICONS.cart },
      { title: 'Suggested', path: PATH_SUGGESTED.root, icon: ICONS.ecommerce },
      { title: 'Our Favourites', path: PATH_OUR_FAVOURITES.root, icon: ICONS.chat },
      { title: 'How it Works', path: PATH_PAGE.about, icon: ICONS.external },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'users',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //       disabled: true,
  //     },
  //   ],
  // },
];

export default navConfig;
