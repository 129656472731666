// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function ConfPolicy() {
  return (
    <>
      <Helmet>
        <title>Confidentiality Policy</title>
      </Helmet>
      <Typography variant={'h3'}>Confidentiality Policy</Typography>
      <Typography paragraph>Info to appear here.</Typography>
  </>
  );
}
