// @mui
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function CookiePolicy() {
  return (
    <>
      <Helmet>
        <title>Cookie Policy</title>
      </Helmet>
      <Typography variant={'h3'}>Cookie Policy</Typography>

      <Typography paragraph>To sum up: Clippitt uses cookies and similar technologies to provide our products and services. Some are essential for our websites to work, while others enable features that enhance your product experience or help Clippitt operate efficiently. If you choose to delete or block cookies, some website features may not work as intended.</Typography>

      <Typography variant={'h5'}>Essential Cookies</Typography>
      <Typography paragraph>These cookies and similar technologies are necessary for our websites to work properly, including authentication and logging in, ensuring the ability to securely complete transactions, preventing fraud, maintaining the security, safety and integrity of Clippitt’s services. They also help us provide a smooth user experience by remembering your preferences during site visits.</Typography>

      <Typography variant={'h5'}>Functional Cookies</Typography>
      <Typography paragraph>These cookies and related technologies are essential for ensuring that all the extra functions on our websites work smoothly. This includes retaining your language preferences and various other types of settings.</Typography>

      <Typography variant={'h5'}>Analytics Cookies</Typography>
      <Typography paragraph>Our cookies and similar tools help us understand and improve how our websites are used, including user behaviour and usage patterns.</Typography>

      <Typography variant={'h5'}>Marketing Cookies</Typography>
      <Typography paragraph>These cookies and related technologies assist Clippitt in promoting our services to current and potential creators and members. They are utilised to assess the effectiveness of marketing campaigns, identify the target audience, and deliver personalised advertising for Clippitt’s products and services based on your preferences.</Typography>

      <Typography variant={'h5'}>"Do-not-track" signals</Typography>
      <Typography paragraph>Your browser may be set to send a "Do Not Track" signal to websites you visit. However, there's no standard interpretation for this signal. Therefore, Clippitt does not monitor or respond to these "Do Not Track" signals.</Typography>

      <Typography variant={'h5'}>Managing Cookies</Typography>
      <Typography paragraph>To take control of your cookie settings, you can view and customize your preferences for the current browser on clippitt.com by clicking the "Edit Cookie Preferences" button at the top of this page. Additionally, you may have the option to modify your web browser's settings to reject cookies, remove them, or receive alerts when a website is trying to set a cookie. Referencing your web browser's documentation can provide further details on these options.</Typography>
  </>
  );
}
