// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about',
  support: '/support',
  contact: '/contact-us',
  faqs: '/faqs',
  legal: '/legal',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: '/home',
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    // videoDetails: path(ROOTS_DASHBOARD, '/watch'),
    videoDetails: (id: string) => path(ROOTS_DASHBOARD, `/watch/${id}`),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  channel: {
    channelDetails: (id: string) => path(ROOTS_DASHBOARD, `/channel/${id}`),
  },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  // },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    content: (id: string) => path(ROOTS_DASHBOARD, `/user/account/content/${id}`),
  },
};

export const PATH_LATEST = {
  root: '/latest',
};

export const PATH_TRENDING = {
  root: '/trending',
};

export const PATH_FAVOURITES = {
  root: '/favourites',
};

export const PATH_OUR_FAVOURITES = {
  root: '/our-favourites',
};

export const PATH_HISTORY = {
  root: '/history',
};

export const PATH_PURCHASED = {
  root: '/purchased',
};

export const PATH_SUGGESTED = {
  root: '/suggested',
};

