// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function CommGuidelines() {
  return (
    <>
      <Helmet>
        <title>Community Guidelines</title>
      </Helmet>
      <Typography variant={'h3'}>Community Guidelines</Typography>

      <Typography paragraph>When creating content on Clippitt, it's important to embrace the diverse range of creators and communities that make up the platform. Respect for others and their contributions is essential for maintaining a safe and welcoming environment. By following these community guidelines, we can ensure that Clippitt remains a place where everyone feels valued and respected.</Typography>

      <Typography variant={'h5'}>How these guidelines are enforced</Typography>

      <Typography paragraph>The Clippitt admin team with help from the Clippitt community is responsible for enforcing the guidelines that govern the platform. While content disagreements may not necessarily violate the community guidelines, users are encouraged to report any violations using the reporting tool. The Clippitt ‘Customer Experience Team' carefully reviews all reports and informs the creator if a violation is confirmed. Clippitt's moderation team is committed to a fair and transparent decision-making approach when dealing with guideline violations. The first approach is to educate creators about rule breaches and support them in resolving issues. However, serious or intentional guideline violations may result in account suspension or removal, and creators may even be banned from using Clippitt altogether in extreme cases. Clippitt also holds fundraisers accountable for their activities on and off the platform. Therefore, the review process may also consider how funds are utilised outside of Clippitt. Appeals against decisions are allowed, and feedback from creators is carefully considered, although changes to decisions are infrequent. The guidelines are critical to maintaining a safe and inclusive environment on Clippitt, where everyone feels respected and valued. They serve as a framework for creating and sharing content that upholds community standards and prevents harmful behaviour. By following these guidelines, users can contribute to a welcoming and positive environment on Clippitt, where all voices are heard and respected.</Typography>

      <Typography paragraph>Clippitt places utmost importance on user safety and promotes responsible conduct to foster a healthy and inclusive online community.</Typography>

      <Typography paragraph>Be safe, be respectful, create something special and join a growing community of like-minded people</Typography>

      <Typography paragraph>These guidelines have been established to provide direction and shape Clippitt and the increasing number of content creators and communities that utilise it. The aim is to establish an environment where diverse creators and their communities feel happy, valued, supported, and eager to participate, even when differing or conflicting viewpoints are expressed. These guidelines serve as a condensed version of our comprehensive, deliberate, and continuously developing internal policy which has been created with input from community and safety experts. We take pride in these guidelines as we believe they will assist creators in creating a secure and encouraging space where they can continue to produce content, share their work, and cultivate enduring relationships with their followers.</Typography>


      <Typography variant={'h5'}>Authenticity</Typography>

      <Typography paragraph>Clippitt is a platform for creators who make original content.</Typography>

      <Typography paragraph>As a creator, you are not allowed to post content that infringes on someone else's intellectual property rights. This means you cannot create fake pages or collect money for things you are not actually doing. For example, you cannot create a fan page for someone else or pretend to be someone else by using their name, brand, or work to deceive people.</Typography>

      <Typography paragraph>We will review satire and comedy in a different way because we believe they are original creations. However, you cannot use Clippitt as a prank or to fund non-activity. For example, you cannot collect funds for not doing something like "I will stop posting videos of playing the drums badly if I reach £150 per month."</Typography>

      <Typography paragraph>Deepfakes are manipulated or digitally-altered audio, video, or image works that portray a person, event, or scene in a way that is not real or did not occur. Examples include superimposing someone's face onto a different body or manipulating audio recordings to fabricate audio of someone saying something they did not say. We prohibit deepfakes that intimidate, bully, or harass someone, encourage violence against them, or harm their reputation. We also prohibit deepfakes that incorporate someone's image or likeness in sexualized contexts in which they did not partake. Additionally, we prohibit deepfakes that use a public figure's image, likeness, or voice to deceive people through fraudulent product endorsements, promotions, phishing, financial scams, and merchandise sales, among others.</Typography>

      <Typography paragraph>However, deepfakes of public figures are allowed if they are satirical or parodic in nature, or focus on political or social commentary. Examples of these types of deepfakes may include using humour, irony, sarcasm, exaggeration, or imitation to critique an individual, institution, or societal issue.</Typography>


      <Typography variant={'h5'}>Violent and graphic content</Typography>
      <Typography paragraph>Clippitt aims to provide a safe environment for all users. We do not allow content that promotes violent behaviour, is graphic, sadistic, or glorifies real-world suffering. Moreover, content that depicts violent or graphic imagery, such as animal abuse, decapitation, gore, and violent death is strictly prohibited. However, there are a few exceptions to this rule - we allow limited content that is educational, scientific, documentary, or newsworthy in nature. Fictional or dramatised content that contains violent and graphic elements is allowed on the platform, provided the creator provides enough context to help viewers understand that it is purely fictional or dramatised.</Typography>
      <Typography paragraph>In addition, we allow content that involves competitive or religious activities such as hunting, or the practice of slaughtering animals as a part of organised religion/faith. We also allow professional combat sports like boxing and mixed martial arts. However, please note that this list of exceptions is not exhaustive. If you have any questions about whether your content would be accepted under these criteria, please contact our Clippitt Customer Experience team.</Typography>
      <Typography paragraph>Do not post content on Clippitt if it contains the following:</Typography>
      
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Promotion of real-world violence:" />
          <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Content that encourages violent acts resulting in death or injury or urges others to engage in violence." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Celebration or glorification of domestic violence." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Depiction of videos filmed by the perpetrator during a violent attack resulting in visible or audible harm or death to victims." />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Depictions of violence against real animals:" />
          <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Content depicting animal slaughter, gore, or animal abuse." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Content where humans use excessive violence towards an animal, such as beating or hitting an animal." />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Depictions of violence against real humans:" />
          <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Content depicting violent accidents, death, and/or human corpses." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Depiction of gore such as body parts, burning, or decomposed bodies in a non-medical context." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Real-world physical violence, including torture." />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Portrayal of made-up or fictional material:" />
          <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Fictional material that romanticizes domestic violence and other abuse within relationships." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Violent or detailed portrayals of fiction or drama that lack an explanation or background to clarify for viewers that the content is not real." />
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Typography variant={'h5'}>Bullying, harassment and threats</Typography>

      <Typography paragraph>Bullying, harassment, and threats are not tolerated on Clippitt. We want our platform to be a safe space for all of our communities. We understand that people may have differing opinions and want to express themselves, but we draw the line at harmful behaviour. While we recognize that discussing public figures can sometimes involve criticism, we do not allow harassment directed towards private individuals. We also do not allow any actions that may compromise the physical safety of others, regardless of whether they are public or private figures. We want all of our users to feel comfortable expressing themselves and sharing their opinions without feeling intimidated. Even challenging conversations should take place in a respectful manner. Here is how we define these behaviour's:</Typography>

      <Typography paragraph>As bullying and harassment are not permitted, this means that you should not attempt to intimidate anyone, whether directly or indirectly by using your influence over others. We take real-life interactions more seriously than online interactions when determining whether a line has been crossed because they can be more threatening and potentially lead to physical violence. If both sides engage in similar behaviour, such as feuds between public figures, we are less likely to take action.</Typography>

      <Typography paragraph>Clippitt is committed to enforcing a strict policy against any content that includes non-consensual intimate images, defined as sexual or pornographic material of an individual or individuals distributed without their consent. However, we do allow content related to non-consensual intimate imagery in the form of survival stories, educational resources, and advocacy.</Typography>

      <Typography paragraph>On Clippitt, everyone has the right to express their views without posing a threat to anyone else. We take any kind of violent threat very seriously and will remove any creator or user who threatens the safety of an individual or a group. Such threats may include stalking or encouraging others to commit violent acts. If you are a victim of such behaviour and feel that your personal safety is at risk, we recommend that you contact your local law enforcement in addition to reporting the behaviour to us.</Typography>

      <Typography variant={'h5'}>Hate speech</Typography>

      <Typography paragraph>Clippitt is a global platform that connects creators with their followers from all over the world. Our mission is to promote creativity and inclusivity. As such, we do not tolerate projects that fund hate speech, which includes any calls for violence, exclusion, or segregation. This also encompasses serious attacks on people based on their race, ethnicity, national origin, religion, sex, gender identity, sexual orientation, disability, serious medical conditions, caste, or immigration status.</Typography>
      <Typography paragraph>When assessing an account for a possible breach related to hate speech, we take into account the following:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Does the content incite violence against individuals based on specific characteristics?" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Is there dehumanizing language used to describe people with certain characteristics?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Are racial slurs or negative portrayals of individuals based on specific traits employed by the creator?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Is there mockery or denial of the experiences of hate crime victims, such as those from the Holocaust?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Does the content unfairly target individuals based on their immigration status, rather than discussing immigration policy in a neutral manner?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Can it be considered legitimate criticism of an entity or belief, or is there an underlying use of coded language to disparage individuals based on particular characteristics?" />
        </ListItem>
      </List>
      <Typography paragraph>This list is not exhaustive, but we aim to be open about our approach in the ambiguous area between speech and action. Distinguishing political commentary from hate speech can sometimes be challenging. Should you encounter content that you consider to be hate speech on Clippitt, we encourage you to report it promptly.</Typography>
      <Typography paragraph>When assessing an account for possible hate speech, we consider the following:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Is there a call for violence against someone based on a characteristic?" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Does the creator use dehumanising language when talking about people with a specific characteristic?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Is the creator using racial slurs or negative depictions of someone based on a particular characteristic?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Does the creator mock or deny the experience of victims of hate crimes such as the holocaust?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Is the creator targeting people based on their immigration status in a discriminatory way, or simply discussing immigration policy?" />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Is it legitimate criticism, or is there an underlying intention to attack individuals based on certain characteristics?" />
        </ListItem>
      </List>

      <Typography variant={'h5'}>18+ creations and mature themes</Typography>
      <Typography paragraph>As we strive to build a diverse and inclusive community, we understand that our users have different ages and sensitivities. Certain types of content are strictly forbidden on Clippitt, and as we are a family-oriented brand, we aim to be family inclusive and we believe children have the right to view Clippitt without infringing our terms. We define "mature 18+ themes" as any audio, visual, or audiovisual content that depicts explicit sexual situations, including nudity in sexual contexts. We ask creators to refrain from adding any content if it may be considered inappropriate for individuals under 18 years of age. Although the exact age threshold may vary depending on the location, we believe that most places require a certain level of maturity to view such content and engage in these activities.</Typography>
      <Typography paragraph>Here are some examples of content that we consider to contain mature themes:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Works depicting real people in the nude in sexual contexts." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Animations or illustrations depicting nudity in sexual contexts, and animations or illustrations featuring sexual interactions." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Audio content that features simulated sexual interactions." />
        </ListItem>
      </List>

      <Typography paragraph>Examples of content we won't view as mature:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Works featuring nudity that is not designed to be sexually gratifying, such as photos of sculptures or paintings." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Works featuring nudity or sexual situations that are designed for educational purposes." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="A standup comedy set featuring profanity or “adult” language." />
        </ListItem>
      </List>
      <Typography paragraph>We require all creators to maintain a family-friendly environment on their Clippitt pages, including profile pictures, membership tier descriptions, and page banners.</Typography>

      <Typography variant={'h5'}>Nudity:</Typography>
      <Typography paragraph>It is strictly forbidden to post nude videos on Clippitt, real or fake. We are a family-oriented site and that means we take a zero-tolerance policy to full nudity.</Typography>
      <Typography paragraph>Please be aware that it is strictly prohibited to post or share any explicit content featuring individuals under the age of 18, including yourself. We take the safety of children very seriously and will cooperate with law enforcement authorities in cases of child exploitation material. For further information, you can refer to our policy on pornography which is detailed in the next section. We do understand that you may create content that is intended for adults, but please note that all your work will be evaluated within its context. If you have any concerns, please do not hesitate to contact us and we will be happy to provide you with guidance on how to maintain a dual presence.</Typography>

      <Typography variant={'h5'}>Pornography and sexual services:</Typography>
      <Typography paragraph>Please note the following policy regarding pornography and sexual services on Clippitt. We strictly prohibit pornography, which includes any images or videos depicting humans engaging in sexual activities, including but not limited to masturbation and sexual intercourse. This policy applies to real-life representations of humans as well as photorealistic depictions of humans, no matter how the videos were created. Creations that deviate from reality in terms of visual representations, such as animations, cartoon drawings, comic strips or graphic novels, are also forbidden. It's important to note that we do not allow any sexual services on Clippitt.</Typography>

      <Typography variant={'h5'}>Offensive and graphic creations:</Typography>
      <Typography paragraph>We understand that artists sometimes have to push boundaries to create thought-provoking art, so we don't review offensive and graphic creations with strict black and white guidelines. We instead review creations in the context of the whole creator page. However, we have zero tolerance when it comes to the glorification of sexual violence which includes bestiality, rape, and child exploitation (i.e., sexualised depiction of minors). This is true for illustrated, animated, or any other type of creations. Clippitt reserves the right to review and remove accounts that may violate this guideline. We also do not allow other fringe sexual fetish creations, such as incest, necrophilia, or fetish creations that are hard to distinguish from non-consensual sex. We understand that some topics on this list such as incest or rape are a little bit more complicated because these situations are, unfortunately, part of real life. As a result, when reviewing these types of creations, the Customer Experience team will take into consideration context including personal, historical or educational narrative. For example, survivor stories or fiction such as Lolita are allowed on Clippitt.</Typography>

      <Typography variant={'h5'}>Doxing or Sex Pics</Typography>
      <Typography paragraph>We strongly condemn the practice of doxing, which involves sharing an individual's private information or compiling their public details to intimidate and harass them. Our firm opposition to doxing stems from its use as a tool to silence or intimidate individuals with differing perspectives on the internet. This behaviour can inflict significant emotional harm and jeopardise the victim's physical safety. Doxing traditionally entails revealing an individual's confidential personal data, such as phone numbers or home addresses. The definition of private personal information may vary but generally refers to challenging data to obtain. Furthermore, we classify it as doxing when someone aggregates an individual's non-private information. For instance, combining a person's profile picture with their real name, all known social media accounts, and their place of residence would constitute doxing in our view. Regardless of the specific details disclosed, we carefully assess the context surrounding why it was shared. If it appears that this information is being disseminated solely to facilitate widespread harassment against that individual, we consider it a case of doxing. When doxing occurs, meaningful conversations are hindered; rather than engaging in constructive speech exchange, it becomes about silencing others' voices. Ultimately, it undermines our ability to have open dialogue around varied ideas—limiting intellectual growth and impeding healthy discourse on disagreements.</Typography>

      <Typography variant={'h5'}>People who can't use Clippitt</Typography>
      <Typography paragraph>People who are unable to utilise Clippitt in brief: Due to the financial empowerment provided by Clippitt, we have limitations on the types of media and projects eligible for funding through Clippitt. Additionally, there are restrictions on individuals who can receive funds via Clippitt. Once a creator has established a page on Clippitt, any instance of creating credible violent threats, committing violent crimes, engaging in malicious doxing or coordinating non-violent harm (such as fraud, money laundering and gambling) may result in them being prohibited from using Clippitt.</Typography>

      <Typography variant={'h5'}>Politicians:</Typography>
      <Typography paragraph>It is not permissible to utilise Clippitt for financing your own political candidacy or backing the campaigns of other candidates.</Typography>

      <Typography variant={'h5'}>Criminal or harmful past:</Typography>
      <Typography paragraph>Individuals who have completed their sentence for past offenses may share their works on Clippitt. However, those found engaging in or encouraging violent threats or acts, child abuse, doxing, nonviolent harm such as fraud and money laundering, or the encouragement of these activities may be banned from using Clippitt.</Typography>

      <Typography variant={'h5'}>Violent, criminal, and hateful organisations:</Typography>
      <Typography paragraph>Violent, criminal, and hateful groups: Individuals or initiatives linked to perilous, intolerant, or aggressive organisations advocating their corresponding beliefs and conspiracy theories or utilising their secret codes and slogans may face limitations in using Clippitt. Adherents of these groups and individuals who express admiration for or endorse the actions of said groups may also encounter restrictions. The encompassed entities consist of (but are not confined to) terrorist factions; well-organised unlawful associations such as drug cartels or malevolent hackers; and supremacist movements like fascist, white nationalist/supremacist factions along with anti-Muslim/anti-Semitic coalitions, as well as any group aiming to harm LGBTQI+ communities. Creators have the liberty to address these groups in their content for educational purposes, reporting relevant information, or debunking myths. However, actively promoting or glorifying these groups through content distributed via Clippitt could lead to account removal. We acknowledge that people's viewpoints can evolve over time. Hence we will always be receptive if an individual disengages from a hazardous organisation's rhetoric thereby wishing to support a project conforming with our guidelines through Clippitt funding opportunities. We will review renunciations of previous activities and affiliations on an individual basis taking into consideration factors such as the duration since the person's disassociation from the group & its ideology plus what they intend to finance on Clippitt after leaving it behind. If you require clarity on whether a specific group complies with Clippitt's standards, please contact support@clippitt.com.</Typography>

      <Typography variant={'h5'}>Harmful and illegal activities</Typography>
      <Typography paragraph>We do not permit the collection of funds for any activities that promote harm or illegal actions. This includes advocating, threatening, or depicting harm to oneself, others, or animals as well as promoting illegal activities such as property crime, distribution of illegal weapons, and drug manufacturing.</Typography>

      <Typography variant={'h5'}>Self-harm:</Typography>
      <Typography paragraph>We do not permit any content that promotes or romanticizes self-harm, including those encouraging self-injury, suicide, or eating disorders. This encompasses content glorifying self-scarification or advocating for pro-anorexia movements. If we assess a potential risk of the creator or member harming themselves, we will collaborate with law enforcement as necessary. However, we support creations aimed at raising awareness like personal stories of overcoming self-harm, particularly if they offer educational value. If you or someone close to you is at risk of harming themselves, please reach out to local authorities or seek help from: https://www.samaritans.org/</Typography>

      <Typography variant={'h5'}>Illegal activity:</Typography>
      <Typography paragraph>Pages are prohibited from gathering funds for illegal purposes or promoting unlawful activities such as weapons, drug production and distribution, or property crime. However, each case is reviewed individually. While we do not permit content that promotes vandalism, street art is allowed within the guidelines.</Typography>

      <Typography variant={'h5'}>Dangerous activities:</Typography>
      <Typography paragraph>For creators posting dangerous stunts, we urge them to include a transparent disclaimer. Additionally, these posts must have a trailer and be labelled as "Members Only" if they contain content that is excessively graphic or hazardous for the general public.</Typography>

      <Typography variant={'h5'}>Teen safety Measures:</Typography>
      <Typography paragraph>Teen Safety Measures: Clippitt strictly prohibits any form of exploitative content showing sexualised minors, irrespective of the method used to create such content. This includes hand-drawn, animated, photorealistic, or computer-generated material depicting sexual abuse involving minors, as well as actions like grooming or solicitation. Any user found involved in facilitating or attempting exploitation of minors will be immediately removed and reported to the appropriate authorities. Furthermore, creators must be at least 18 years old to share modelling or body-centric content on Clippitt.</Typography>

      <Typography variant={'h5'}>Disinformation:</Typography>
      <Typography paragraph>Clippitt does not support funding for content that spreads medical misinformation about Covid-19 or promotes other global conspiracy theories.</Typography>

      <Typography variant={'h5'}>Spam</Typography>
      <Typography paragraph>To sum up: Growing a Clippitt supporter base can be challenging and rewarding, but nobody appreciates spam. Instead, try to showcase creativity and uniqueness by avoiding repetitive comments or unwanted private messages to other community members.</Typography>
      <Typography paragraph>Here are some actions that we consider as spamming, which is not allowed on Clippitt:</Typography>

      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Avoid creating schemes for patrons or contacting other creators for pledge-for-a-pledge trade deals." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Refrain from using Clippitt to distribute malware or phishing links." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Do not make deceptive posts in an attempt to increase your supporter/subscriber count." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Resist the urge to post comments or content on other creators' pages solely promoting your own page." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Avoid sending numerous unsolicited private messages asking for support." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Don't manipulate tags incorrectly just to appear in more search results." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="If you are a PPV creator, refrain from posting multiple paid posts per day." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="When assessing reports of spam, commercial spam will be distinguished from friendly abuse such as legitimate creators excessively promoting themselves on others' pages." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="If deemed friendly abuse, the account will be flagged; repeated violations may result in removal of the creator's channel or members page." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Any non-legitimate creator engaged in commercial spamming will be removed from Clippitt. Therefore, strive towards originality and creativity because no one enjoys Spam (not the canned type)." />
        </ListItem>
      </List>

      <Typography variant={'h5'}>Clippitt accounts</Typography>
      <Typography paragraph>Clippitt guideline enforcement: In essence, the Community Guidelines are designed to empower both creators and subscribers. While creators have control over their communities, there are situations where reports of user behaviour will be reviewed by the Customer Experience team. The team takes a thorough approach when reviewing reports related to hate speech, harassment, and solicitation. Action will be taken against any user account that violates these guidelines. Creators have the ability to report creators' posts directly using specific instructions provided. It's important to note that all reviews are kept confidential. Most importantly, if you ever feel unsafe due to stalking or harassment from a user, it is advisable to reach out for assistance from local law enforcement or cyber-crime units for specialised help in such cases. Clippitt cooperates closely with law enforcement during investigations and responds accordingly if presented with a summons.</Typography>

      <Typography variant={'h5'}>Financial advice content:</Typography>
      <Typography paragraph>Clippitt prioritises the safety of creators and users, therefore it is strongly recommended for individuals consuming financial advice content to seek guidance from licensed financial professionals prior to making any investment choices.</Typography>

      <Typography variant={'h5'}>Chargebacks:</Typography>
      <Typography paragraph>When someone, such as creators, members or users of Clippitt, disputes a transaction with their financial institution, it can lead to chargebacks. In order to safeguard creators, Clippitt carefully examines excessive and potentially deceitful chargebacks and may restrict users from subscribing to additional creators while the review is in progress. If fraudulent activity is confirmed in a user's chargebacks, Clippitt has the authority to suspend or deactivate their account. Additional details on chargebacks can be accessed here. These regulations are outlined in Clippitt's Terms of Use. To learn more, reach out to: support@clippitt.com.</Typography>
      <Typography paragraph>We make every effort to provide an amazing user experience and as we are fairly new, we are learning all the time, and we appreciate your help and support in making Clippitt the UK's no.1 video-sharing platform.</Typography>
    </>
  );
}
