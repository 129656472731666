import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Clippitt',
    children: [
      { name: 'Help & Support', href: PATH_PAGE.support },
      { name: 'How it Works', href: PATH_PAGE.about },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms of Use', href: PATH_PAGE.legal + '/terms' },
      { name: 'Confidentiality Policy', href: PATH_PAGE.legal + '/confidentiality-policy' },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          ©2023 All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        mt: 3,
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />
      <Grid
        container
        justifyContent={{
          xs: 'center',
          md: 'space-between',
        }}
        sx={{
          mt: 2,
          textAlign: {
            xs: 'center',
            md: 'left',
          },
        }}
      >

        <Grid item xs={12} md={6}>
          <Stack
            spacing={5}
            justifyContent="space-between"
            direction={{ xs: 'column', md: 'row' }}
          >
            {LINKS.map((list) => (
              <Stack
                key={list.headline}
                spacing={2}
                alignItems={{ xs: 'center', md: 'flex-start' }}
              >
                <Typography component="div" variant="overline">
                  {list.headline}
                </Typography>

                {list.children.map((link) => (
                  <Link
                    key={link.name}
                    component={RouterLink}
                    to={link.href}
                    color="inherit"
                    variant="body2"
                  >
                    {link.name}
                  </Link>
                ))}
              </Stack>
            ))}
          </Stack>
        </Grid>

        <Grid item xs={8} md={3}>
          <Stack
            spacing={1}
            direction="row"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            mt={{xs: 5, md: 0}}
          >
            {_socials.map((social) => (
              <IconButton key={social.name} href={social.path} target={'_blank'} rel={'noreferrer'}>
                <Iconify icon={social.icon} />
              </IconButton>
            ))}
          </Stack>

          <Typography
            variant="caption"
            component="div"
            sx={{
              mt: 5,
              textAlign: { xs: 'center', md: 'right' },
            }}
          >
            Clippitt © 2024. All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  return isHome ? simpleFooter : mainFooter;
}
