// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function UserGuidelines() {
  return (
    <>
      <Helmet>
        <title>User Guidelines</title>
      </Helmet>
      <Typography variant={'h3'}>User Guidelines</Typography>

      <Typography variant={'h5'}>How are these guidelines reinforced?</Typography>
      <Typography paragraph>Most creators just want to share something special with their fans, but certain benefits are limited. The Customer experience team does a thorough analysis to determine what members are paying for and the value they receive, ensuring that prohibited benefits are not shared away from Clippitt. They review a creator's entire online presence when evaluating their purchasable content and benefits.</Typography>

      <Typography variant={'h5'}>Illegal creations and benefits</Typography>
      <Typography paragraph>Legality is complex due to varying laws in different countries. Therefore, we tailor our policies to suit a global company, rather than specific locations. There are certain types of benefits that Clippitt does not permit anywhere. In some instances, we will also report the account and collaborate with law enforcement to identify the source.</Typography>
      <Typography>Prohibited benefits:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Images of child abuse and sexual depiction of minors." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Images of human or animal abuse." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Access to betting or gambling products or services." />
        </ListItem>
      </List>
      <Typography paragraph>For information on benefits that infringe on intellectual property rights, please see our Copyright policy.</Typography>

      <Typography variant={'h5'}>Drugs, medication and supplements</Typography>
      <Typography>Sometimes individuals may wish to express their journey through difficult times and how they overcame them, with the intention of providing support to others in similar situations. While this is a positive aim, it's crucial that content creators do not promote harmful or risky actions. Hence, you are prohibited from promoting the following as an advantage:</Typography>

      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Items related to drugs or drug-related activities." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Partial payment for medication, supplements, or similar items through coupons, gift cards, or any form of incentive." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Medication or dietary supplements claiming benefits like weight loss, increased energy, or extraordinary cures." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Educational materials and books that assert they have the ability to treat illnesses." />
        </ListItem>
      </List>

      <Typography paragraph>Keep in mind that Clippitt permits content that explores or addresses alternative medicine, as long as it does not endanger people. While there is a subtle distinction between "Medical study on the effectiveness of CBD oil for dementia" and using 'new age therapies' alone to cure cancer, our team consistently evaluates content that may fall into the latter category.</Typography>
      
      <Typography variant={'h5'}>Weapons and explosives</Typography>
      <Typography>We welcome creations about weaponry on Clippitt. However, as a global company, we have some restrictions on the benefits you can offer. You cannot provide the following as Clippitt benefits:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Offering weapons as a benefit such as knives or other harmful products or promoting the purchase and use of same." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="The promotion of firearms or explosives." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Tutorials, instructions, or demonstrations for crafting or modifying weapons or explosives." />
        </ListItem>
      </List>

      <Typography variant={'h5'}>Alcohol and smoking</Typography>
      <Typography paragraph>Feel free to dedicate a page to the art of beer tasting or cocktail crafting. You can also explore cigar reviews and delve into the rich history of tobacco. However, there are certain limitations on the benefits you can provide. </Typography>
      <Typography paragraph>You are not permitted to promote or use selling techniques through Clippitt to promote your own website or use 3rd party portals including selling the following:</Typography>
      <Typography paragraph>Alcohol and smoking substances such as tobacco, cigars, vapes, e-cigarettes, rolling paper and cigarettes. Or home-based brewing kits.</Typography>
      
      <Typography variant={'h5'}>Finance and cryptocurrency</Typography>
      <Typography>Feel free to engage in discussions and showcase your creations, offer financial guidance, and delve into the world of cryptocurrency. However, Clippitt is not intended for launching an Initial Coin Offering or establishing an investment program. So, while you can share your innovative work and expertise in these areas, you are not permitted to provide the following advantages:</Typography>

      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Incorporating coins as a benefit for users or inviting them to become investors." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Allowing partial payment using coupons, gift cards, or any reward in exchange for purchasing or acquiring cryptocurrency." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="Creators are prohibited from creating benefits intended to perpetrate fraud, such as pyramid schemes or Ponzi schemes." />
          </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='You cannot provide benefits that guarantee users a financial gain based on following advice provided in the benefit or tip, such as "if you pay £50 a month, my advice will earn you 10 times that.' />
        </ListItem>
      </List>

      <Typography variant={'h5'}>Sex work and sexual services</Typography>
      <Typography paragraph>This only applies to creators who adhere to both our Community Guidelines and User Guidelines. Distinguishing between creations and experiences is a key aspect outlined in our Community Guidelines. We acknowledge that creators may occasionally seek to challenge societal norms, but we do not permit funding for pornography or sexual services on Clippitt. Therefore, the following benefits cannot be offered:</Typography>
      <Typography paragraph>You are not allowed to utilise Clippitt for sharing adult content, including providing access to pornographic websites or private access to sexual content on any platform. Additionally, you cannot offer sexually explicit or intimate items, fund escort services using Clippitt's payment system, or provide erotic webcam sessions to your subscribers. Creators featuring real people who are 18+ cannot offer exclusive benefits such as custom videos or audio files made for a single user; however, they can engage their users in the creative process within our guidelines.</Typography>

      <Typography variant={'h5'}>Health, hygiene and consumables</Typography>
      <Typography paragraph>Content related to culinary creations, crafting homemade goods, and addressing wellness topics is permitted. However, content creators are not allowed to provide links to consumable ingredients or products as rewards. They may, however, offer links to non-ingestible items such as lotions or perfumes as incentives.</Typography>

      <Typography variant={'h5'}>Multi-level marketing and direct selling</Typography>
      <Typography paragraph>Clippitt is a platform for creators to launch creative projects, not for direct sales or Multi-Level Marketing. It's meant to support creative endeavours rather than promote direct sales businesses. Products from direct marketing companies cannot be offered as benefits on Clippitt.</Typography>

      <Typography variant={'h5'}>Raffles and Prizes</Typography>
      <Typography paragraph>Raffles and giveaways are undoubtedly exciting, capturing the imagination of many. However, it's important to remember that soliciting payment for participation in such events is not permissible. Whether it's lotteries, raffles, or mystery boxes with uncertain values, entrants should never have to pay for the opportunity to win. To engage your followers through giveaways while staying compliant with relevant laws, ensure that these opportunities are available to your entire fan base rather than just members. </Typography>

      <Typography variant={'h5'}>Copyright & trademark in merchandise</Typography>
      <Typography paragraph>Your content is yours. Clippitt will not use it for advertising or promotional material without your permission. We reserve the right to review and refuse any content that does not align with our guidelines and Terms of Use.</Typography>
      <Typography paragraph>Merchandise promoting harassment or threatening behaviour towards individuals or groups will not be allowed on Clippitt, including content that incites violence or stalking.</Typography>

      <Typography variant={'h5'}>Other things to note</Typography>
      <Typography paragraph>This is not a complete list and could be subject to change in the future due to advancements in technology and other unforeseen events. If you have any concerns about your projects, please reach out to the Customer experience team by emailing support@clippit.com.</Typography>
      <Typography paragraph>We are here to provide assistance whenever needed.</Typography>
      <Typography paragraph>This policy forms part of Clippitt's Terms of Use as well as our Community Guidelines. For further details, feel free to get in touch with us at support@clippitt.com.</Typography>
  </>
  );
}
