// @mui
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function GDPRPolicy() {
  return (
    <>
      <Helmet>
        <title>GDPR Policy</title>
      </Helmet>
      <Typography variant={'h3'}>GDPR Policy</Typography>
      <Typography variant={'subtitle2'} paragraph>Effective Jan 1st 2024.</Typography>
      <Typography variant={'h4'}>GDPR Privacy Policy Summary</Typography>
      <Typography paragraph>We are dedicated to safeguarding your personal data and upholding your privacy rights. If you have any queries or worries regarding our policy or how we handle your personal information, feel free to reach out to us at support@clippitt.com.</Typography>
      <Typography paragraph>When you visit our website "Clippitt" or "Clippitt.com" and use our services, your privacy is important to us. We take this responsibility seriously and have outlined how we collect and use your personal information in a clear and understandable way. It's crucial for you to review it carefully. If there are any terms in the policy that you do not agree with, please stop using our site and services.</Typography>

      <Typography paragraph>This privacy policy pertains to all data gathered through our website "Clippitt" or "Clippitt.com", including any subdomains of this site/service or other sites owned by or operated on behalf of High Peak Telecommunications Ltd.</Typography>
      <Typography paragraph>Please carefully review this privacy policy to make well-informed decisions about sharing your personal information with us.</Typography>
      <Typography paragraph>Thank you for choosing to be part of our community at Clippitt, a company incorporated in the United Kingdom, under registration number 12920226 and registered office address at Clippitt HQ, Springbank Gardens, New Mills, High Peak, SK22 4BJ. doing business as Clippitt or (Clippitt.com) ("Clippitt", "we", "us", or "our").</Typography>

      <Typography variant={'h4'}>What information do we collect?</Typography>
      <Typography paragraph>Personal information you disclose to us.</Typography>
      <Typography paragraph>In brief:</Typography>
      <Typography paragraph>We gather the personal details you share with us, including your name, address, contact information, date of birth, passwords and security data, and payment information through a 3rd party.</Typography>
      <Typography paragraph>Our collection of personal information is based on what you voluntarily provide when showing an interest in obtaining information about our products and services or when engaging in activities on the site or reaching out to us.</Typography>
      <Typography paragraph>The specific personal details we collect depend on how you interact with us and the site, as well as the choices you make and the products and features utilised. The types of personal data we may gather include:</Typography>
      <Typography paragraph>Identification and Contact Information: We gather your given name, surname, email address, mailing address, phone number, date of birth and comparable contact details.</Typography>

      <Typography paragraph>User Credentials: We obtain passwords, and related security details utilised for verification and accessing the account.</Typography>

      <Typography paragraph>Our payment gateway operator or 'processor', gathers the information required to handle your payment when you make purchases, including your payment method number (such as a credit card number) and its associated security code. Our payment processor stores all payment data, so we recommend reviewing their privacy policies and contacting them directly for any inquiries.</Typography>

      <Typography paragraph>Answers to questions of multiple choice or other free form text inputs.</Typography>
      <Typography paragraph>Other personal data you voluntarily provide through comments, or chat, or email including but not limited to text, video or voice chat.</Typography>
      <Typography paragraph>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</Typography>

      <Typography variant={'h4'}>Information automatically collected:</Typography>
      <Typography paragraph>When you visit Clippitt, we collect certain information automatically. This information does not reveal your specific identity but may include device and usage details like your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country location details. We use this information primarily to maintain the security and operation of our site as well as for internal analytics and reporting purposes.</Typography>
      <Typography paragraph>Like many businesses, we also collect information through cookies and similar technologies.</Typography>

      <Typography variant={'h4'}>Cookies</Typography>
      <Typography paragraph>Cookies are small text files stored in your browser. They enable us to gather standard Internet log information and visitor behaviour data. When you visit our website, we can automatically collect information from you using cookies or similar technology.</Typography>
      <Typography paragraph>For further information, visit "Clippitt Cookies Policy".</Typography>
      <Typography paragraph>We keep you signed in to make your experience seamless. Understanding how you use our website helps us protect it from potential threats and allows us to gather valuable anonymous data for improving user experience.</Typography>

      <Typography variant={'h5'}>What types of cookies do we use?</Typography>
      <Typography paragraph>There are a number of different types of cookies, however, our website uses:</Typography>
      <Typography variant={'h5'}>Functionality</Typography>
      <Typography paragraph>Our business employs these cookies to identify you on our website and recall your previously chosen settings. These may encompass your language preference and location. A combination of first-party and third-party cookies is utilised for this purpose.</Typography>

      <Typography variant={'h5'}>Aggregation</Typography>
      <Typography paragraph>Our organisation utilises these cookies to gather details about your website visit, the content you accessed, the links you clicked on, and information regarding your browser, device, and IP address. Occasionally, we share specific elements of this data with third parties for security measures. Additionally, we may also distribute aggregated online data collected through cookies with other sub-processors compliant with GDPR regulations.</Typography>

      <Typography variant={'h5'}>Marketing Cookies</Typography>
      <Typography paragraph>We utilise cookies and similar tracking mechanisms (such as web beacons and pixels) to retrieve or store data. Details on our use of these technologies and how you can reject certain cookies are explained in our Cookie Policy.</Typography>

      <Typography variant={'h5'}>How to manage cookies</Typography>
      <Typography paragraph>You can choose not to accept cookies in your browser, but this may cause some of our website features to not function properly.</Typography>

      <Typography variant={'h4'}>Privacy Policy of other websites</Typography>
      <Typography paragraph>Our website may contain links to other websites or provide services to third-party websites. Our Confidentiality policy applies only to our website, so we recommend reading the Confidentiality policy of any linked external sites.</Typography>

      <Typography variant={'h4'}>Information collected form other sources</Typography>
      <Typography paragraph>We may collect limited data from public databases, marketing partners, and other outside sources including API services.</Typography>
      <Typography paragraph>We might collect details about you from alternative sources, including public databases, collaborative marketing partners, and other external parties. Examples of the data we gather from these sources incorporate social media profile details, social media login credentials, marketing leads and search findings and links - encompassing paid listings like sponsored listings.</Typography>

      <Typography variant={'h4'}>How do we use your information?</Typography>
      <Typography paragraph>We use your information for legitimate business interests, fulfilling our contract with you, meeting legal obligations, and/or with your consent.</Typography>

      <Typography paragraph>We use personal information collected via our site for a variety of business purposes. We process your personal information for these purposes based on our legitimate business interests, to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</Typography>
      <Typography paragraph>We use the information we collect or receive:</Typography>
      <Typography paragraph>To provide you with the service and allow usage of our applications.</Typography>
      <Typography paragraph>We may use your personal information for marketing and promotional communications, in line with your preferences. You can easily opt out of receiving our marketing emails at any time.</Typography>
      <Typography paragraph>We may use your personal information to send you important updates about our products, services, and any changes to our terms, conditions, and policies.</Typography>
      <Typography paragraph>We will utilise your details to complete and oversee your purchases, payments, refunds, and product exchanges conducted via the website.</Typography>

      <Typography paragraph>Request Feedback. We may use your information to request feedback and to contact you about your use of our site.</Typography>
      <Typography paragraph>To uphold our terms, conditions, and policies.</Typography>
      <Typography paragraph>In order to address legal requests and minimise potential harm, we may need to examine the data in our possession if we receive a summons, court order or other legal request.</Typography>
      <Typography paragraph>We may utilise your information for additional business purposes, such as conducting data analysis, identifying patterns in usage, assessing the success of our promotional efforts, and enhancing our site, products, services, marketing strategies, all in an effort to enhance your overall experience.</Typography>
      
      <Typography variant={'h4'}>Where we store your information and is it shared with anyone?</Typography>
      <Typography paragraph>Data protection is always a top priority for Clippitt, regardless of where the data is located.</Typography>
      <Typography paragraph>To provide the best user experience, Clippitt utilises cloud solutions and stores information internationally while adhering to GDPR regulations. This means that if data is transferred outside of EEA, it will be safeguarded by similar regulations in place at its physical location as well as internal security policies.</Typography>
      <Typography paragraph>We only share information with your consent, to comply with laws, or to protect your rights.</Typography>
      <Typography paragraph>We may process or share data based on the following legal basis:</Typography>
      <Typography paragraph>Consent: If you have provided us with specific consent to use your personal information for a particular purpose, we are allowed to process your data.</Typography>
      <Typography paragraph>Legitimate Interests: We process your data to achieve our legitimate business interests.</Typography>
      <Typography paragraph>Performance of a Contract: If we have a contract with you, we may use your personal information to meet the requirements of our agreement.</Typography>
      <Typography paragraph>Legal Obligations: We might need to reveal your information if we are obligated by law to do so, to comply with government requests or legal proceedings. This could include responding to a court order or a summons etc. and meeting national security or law enforcement requirements from public authorities.</Typography>
      <Typography paragraph>Vital Interests: We may disclose your information if we need to investigate, prevent, or take action regarding policy violations, suspected fraud, threats to safety, illegal activities, or as evidence in litigation.</Typography>
      <Typography paragraph>We may need to process your data or share your personal information in specific situations:</Typography>
      <Typography paragraph>We may disclose your information to third-party vendors, consultants, service providers, and agents who assist us in conducting our business operations such as payment processing, data analysis, email delivery, hosting services, customer service support and marketing activities. We may also permit selected third parties to utilise tracking technology on the site for purposes such as analysing user interaction with the site over time and gauging content popularity. Except as outlined in this policy, we do not share or trade any of your information with third parties for their promotional use.</Typography>
      <Typography paragraph>We may share or transfer your information in connection with a merger, sale of company assets, financing, or acquisition of our business.</Typography>
      <Typography paragraph>We may disclose your information to our business associates in order to provide you with specific products, services, or promotional offers.</Typography>

      <Typography variant={'h4'}>List of Business Partners:</Typography>
      <List sx={{ listStyle: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Stripe.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Amazon Web Services.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Google Inc.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Facebook.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Instagram.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='YouTube.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='TikTok.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Appism.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Onfido.' />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary='Clicks Talent.' />
        </ListItem>
      </List>

      <Typography variant={'h4'}>How long do we keep your information?</Typography>
      <Typography paragraph>We retain your information for the duration necessary to achieve the objectives specified in this privacy policy, unless mandated by law.</Typography>
      <Typography paragraph>We will only retain your personal information for as long as necessary for the purposes outlined in this privacy policy. Our retention period will not exceed 1 year unless required or permitted by law.</Typography>
      <Typography paragraph>When we no longer have a legitimate business reason to use your personal information, we will either delete or anonymise it. If that's not possible, such as when the information is stored in backup archives, we will securely store and isolate your personal data from any further processing until deletion becomes feasible.</Typography>
      
      <Typography variant={'h4'}>What are your privacy rights?</Typography>
      <Typography paragraph>In certain areas, like the European Economic Area, you are entitled to enhanced rights that offer you increased accessibility and authority over your personal data. You retain the ability to review, modify, or cancel your account at any point.</Typography>
      <Typography paragraph>In certain areas, such as the European Economic Area, you possess specific rights under relevant data protection legislation. These rights may involve the ability to request access and a copy of your personal information, ask for corrections or deletion of information, limit the processing of your personal data, and potentially transfer your data to another service. Depending on the situation, you might also have the right to oppose the processing of your personal information. If you wish to make any such requests, please refer to the contact details provided within this policy. We will review and respond to all requests in compliance with applicable data protection laws.</Typography>

      <Typography variant={'h4'}>GDPR Compliance Statement</Typography>
      <Typography paragraph>We respect and comply with the EU General Data Protection Regulations (GDPR).</Typography>
      <Typography paragraph>Some of the main examples and areas where we comply with these regulations include:</Typography>

      <Typography variant={'h4'}>Consent</Typography>

      <Typography paragraph>We clearly outline the details of what you are agreeing to without using complicated legal language, and we request your explicit consent for us to contact you.</Typography>

      <Typography variant={'h4'}>Breach Notification</Typography>
      <Typography paragraph>In case of a breach, we will inform impacted users within 24 hours of our initial awareness of the breach.</Typography>
      
      <Typography variant={'h4'}>Right to Access</Typography>
      <Typography paragraph>You can ask for confirmation about the processing of your personal data, including its location and purpose. Additionally, we will provide you with a free electronic copy of your personal data.</Typography>
      
      <Typography variant={'h4'}>Right to be Forgotten</Typography>
      <Typography paragraph>Once we have weighed your rights against the public interest in data availability, we may delete your personal data upon request.</Typography>
      
      <Typography variant={'h4'}>Data Portability</Typography>
      <Typography paragraph>You have the right to receive your personal data in a commonly used and machine-readable format, and you can transmit that data to another controller.</Typography>
      
      <Typography variant={'h4'}>Privacy by Design</Typography>
      <Typography paragraph>We implement strong measures to meet the regulations and protect data subjects' rights. We only hold and process necessary data for our duties (data minimisation) and limit access to personal data to those involved in processing it.</Typography>
      <Typography paragraph>If we need your consent to process your personal information, you have the right to revoke it at any time. Keep in mind that this will not impact the legality of the processing that occurred before withdrawal.</Typography>
      <Typography paragraph>If you live in the European Economic Area and think we are handling your personal information unfairly, you have the right to report this to your local data protection supervisory authority. You can find their contact details here:</Typography>
      <Typography paragraph>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</Typography>

      <Typography variant={'h4'}>Do we ever update this policy?</Typography>
      <Typography paragraph>Yes, and we will make necessary updates to this policy in order to ensure compliance with relevant laws.</Typography>
      <Typography paragraph>We update our privacy policy periodically. Any significant changes will be clearly communicated to you. We encourage you to review the policy regularly for updates on how we protect your information.</Typography>

      <Typography variant={'h4'}>How can you contact us about this policy?</Typography>
      <Typography paragraph>If you have any inquiries or feedback regarding this policy, please feel free to reach out to us via email at support@clippitt.com or by mail at: Clippitt HQ, Springbank Gardens, New Mills, High Peak, Derbyshire, SK22 4BJ, United Kingdom.</Typography>

      <Typography variant={'h4'}>How to contact our Data Protection Officer</Typography>
      <Typography paragraph>If you have any complaints or feel that our company has not addressed your concerns adequately, please contact our Data Protection Officer at the following address: Clippitt HQ, Springbank Gardens, New Mills, High Peak, Derbyshire, SK22 4BJ, United Kingdom.</Typography>
      <Typography paragraph>If you require further support due to concerns about our Data Processing Officer decision, you have the legal right to reach out to the European Data Protection Board in your country of residence. For additional details about Member Organisations, please visit the following link:</Typography>
      <Typography paragraph>https://edpb.europa.eu/about-edpb/board/members_en</Typography>
  </>
  );
}
