import { httpsCallable } from "firebase/functions";
import { functions } from ".";
import { CheckoutStore } from "./firestore";
import { MediaType, PortalLink, PublishJob, SubscriptionInterval, SubscriptionJob, UploadJob, UploadUrl } from "./interfaces";

export const purchaseSubscription = async (userId: string, creatorId: string, videoId?: string) => {
  const callable = httpsCallable(functions, 'stripe-purchaseSubscription');
  const cancelUrl = window.location.href;
  let successUrl = window.location.href + (window.location.href.includes('?') ? '&' : '?') + 'purchased=true';
  if (videoId) {
    successUrl += `&videoId=${videoId}`;
  }
  const result = await callable({creatorId, cancelUrl, successUrl});
  return new CheckoutStore(userId, result.data as string);
}

export const purchaseContent = async (userId: string, contentId: string) => {
  const callable = httpsCallable(functions, 'stripe-purchaseContent');
  const cancelUrl = window.location.href;
  const successUrl = window.location.href + '?purchased=true';
  const result = await callable({contentId, cancelUrl, successUrl});
  return new CheckoutStore(userId, result.data as string);
}

export const uploadMedia = async (filename: string) => {
  const callable = httpsCallable(functions, 'brightcove-uploadMedia');
  const result = await callable({filename});
  return result.data as UploadUrl;
}

export const confirmUpload = async (type: MediaType, videoId: string, requestUrl: string, filename: string, contentId?: string) => {
  const callable = httpsCallable(functions, 'brightcove-confirmUpload');
  const result = await callable({type, videoId, requestUrl, filename, contentId});
  return result.data as UploadJob;
}

export const publishContent = async (contentId: string) => {
  const callable = httpsCallable(functions, 'brightcove-publishContent');
  const result = await callable({contentId});
  return result.data as PublishJob;
}

export const setSubscriptionSettings = async (interval: SubscriptionInterval, frequency: number, price: number, benefits?: string) => {
  const callable = httpsCallable(functions, 'clippitt-setSubscriptionSettings');
  const result = await callable({interval, frequency, price, benefits});
  return result.data as SubscriptionJob;
}

export const cancelSubscriptionSettings = async () => {
  const callable = httpsCallable(functions, 'clippitt-cancelSubscriptionSettings');
  const result = await callable();
  return result.data as SubscriptionJob;
}

export const cancelSubscription = async () => {
  const callable = httpsCallable(functions, 'stripe-createPortalLink');
  const returnUrl = window.location.href;
  const result = await callable({returnUrl});
  return result.data as PortalLink;
}

export enum ActionType {
  Like = 'like',
  Subscribe = 'subscribe',
  View = 'view',
  Favourite = 'favourite',
  Unfavourite = 'unfavourite',
  Follow = 'follow',
  Unfollow = 'unfollow',
}

export enum ActionEntity {
  Content = 'content',
  Creator = 'creator',
}

export interface Action {
  type: ActionType;
  entity: ActionEntity;
  id: string;
}

export const recordAction = async (action: Action) => {
  const callable = httpsCallable<Action>(functions, 'clippitt-recordAction');
  return await callable(action);
}
