import { Helmet } from 'react-helmet-async';
// @mui
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function SuggestedPage() {

  return (
    <StyledContainer>
      <Helmet>
        <title>Especially for You</title>
      </Helmet>

      <Typography variant={'h2'}>Especially for You</Typography>
    </StyledContainer>
  );
}
