import { Helmet } from 'react-helmet-async';
// @mui
import { Link, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import Image from '../components/image';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function SupportPage() {

  return (
    <StyledContainer>
      <Helmet>
        <title>Helpdesk and Support</title>
      </Helmet>

      <Typography variant={'h2'} mb={5}>Helpdesk and Support</Typography>
      <Typography mb={2}>To contact our support and help desk, please use our LiveChat Widget by tapping the icon in the bottom right corner of your screen.</Typography>
      <Typography mb={2}>Alternatively you can email <Link href={'mailto:support@clippitt.com'}>support@clippitt.com</Link> and we will respond as soon as possible.</Typography>
      <Typography mb={2}>As Clippitt is continually evolving and we are always improving our platform, we will be continually updating our support features and our LiveChat feature will help navigate the most common support questions raised.</Typography>
      <Typography mb={2}>If our FAQ's section can't help in the first instance, you will be able to speak to a live customer experience manager using our LiveChat service.</Typography>
      <Typography mb={2}>We would also like to thank everyone, including our creator's, members, and our partners, who continue to be instrumental in supporting our quest to become the UK's no.1 monetised video-sharing channel.</Typography>
      <Typography mb={2}>It means the world to us, and we thank you for all your support!</Typography>
      <Image src="/assets/images/livechat.png" sx={{width: 115, height: 100}} alt={'Powered by LiveChat'} />
    </StyledContainer>
  );
}
