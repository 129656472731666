//
import { useEffect, useState } from 'react';
import Image from '../../image';
//
import { CustomFile } from '../types';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
};

export default function AvatarPreview({ file }: Props) {
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getFileURL = async () => {
      if (typeof file === 'string') {
        setImgUrl(file);
      } else {
        setImgUrl(file?.preview);
      }
    }
  
    getFileURL();
  }, [file]);

  if (!file) {
    return null;
  }

  return (
    <Image
      alt="avatar"
      src={imgUrl}
      sx={{
        zIndex: 8,
        overflow: 'hidden',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
      }}
    />
  );
}
